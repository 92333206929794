<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { user } from "./auth.js";
  import { api } from "../../lib/api";
  import { genres } from "../../lib/store.js";
  import sortBy from "lodash/sortBy";

  // Set page and nav titles
  $: metatags.title = "Favorite Venues • NightLife.FM";
  navTitle.set("Favorite Venues");

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  let venuesLikedPromise = fetchItemsLiked("venue", $user);

  // Gets liked Performers and Venues
  async function fetchItemsLiked(type, user) {
    const data = await api.items(type + "s_liked").readMany({
      filter: {
        user_created: {
          _eq: user.id,
        },
      },
      // venue: {
      //   sort: "name",
      // },
      fields: ["venue.*"],
    });

    return sortBy(data["data"], function (item) {
      return [item.venue.name];
    });
  }
</script>

<div class="container">
  {#await venuesLikedPromise then venues}
    <ul class="mx-3 mt-2">
      {#each venues as venue}
        <li>
          <a
            href="/venues/{venue.venue.slug}"
            class="block rounded-lg border border-gray-900 border-opacity-75 bg-gray-900 bg-opacity-50 mb-3 p-3">
            <span class="block">
              <span class="inline-block align-top pl-2">
                <span class="block mb-0.5">
                  <span class="block text-lg font-bold"
                    >{venue.venue.name.substring(0, 30)}</span
                  >
                </span>
              </span>
            </span></a
          >
        </li>
      {/each}
    </ul>
  {/await}
</div>
