<script>
  import { onMount } from "svelte";
  import { api } from "../../lib/api";
  import { Button, Dialog, RadioButtonGroup } from "smelte";
  import { venuesFilter } from "./filters.js";

  let showDialog = false;
  let cities = [];
  let metros = [];

  let metroID;

  onMount(() => {
    metroID = $venuesFilter.metro.id;
  });

  function showMetros() {
    if (cities.length === 0) {
      api
        .items("cities")
        .readMany({
          filter: {
            status: {
              _eq: "published",
            },
          },
          sort: "name",
          fields: ["*"],
        })
        .then((data) => {
          metros = data.data;
          data.data.forEach((city, i) => {
            cities.push({ value: city.id, label: city.name });
          });
          showDialog = true;
        });
    } else {
      showDialog = true;
    }
  }

  function selectMetro() {
    $venuesFilter.metro = metros.find(({ id }) => id === metroID);
    $venuesFilter.neighborhoods = ["Any Neighborhood"];
    showDialog = false;
  }
</script>

<button on:click={showMetros}>
  {$venuesFilter.metro.name}
  <svg
    class="inline align-middle ml-1"
    fill="white"
    height="24"
    viewBox="0 0 24 24"
    width="24"
  >
    <path d="M7 10l5 5 5-5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
</button>

<Dialog
  bind:value={showDialog}
  opacity="0.9"
  class="ml-3 mr-3 pt-2 pb-8 w-screen md:w-1/2 lg:w-1/4"
>
  <div class="text-lg mb-2 ml-5">City</div>
  <div on:click={selectMetro} class="p-3 pb-0">
    <RadioButtonGroup bind:selected={metroID} items={cities} />
  </div>
  <div slot="actions">
    <Button
      on:click={() => (showDialog = false)}
      text
      color="white"
      remove="hover:bg-white"
      icon="cancel"
      iconClass="mr-3"
    >
      Cancel
    </Button>
  </div>
</Dialog>
