<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../../lib/store.js";
  import { Button, Dialog, Select, TextField } from "smelte";
  import { api } from "../../../lib/api";
  import { user } from "../../user/auth.js";
  import slug from "slug";

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  export let performer;

  let title = "NightLife.FM";
  let performerID = null;
  let performerName = "";
  let performerAddressLocality = "";
  let performerAddressCountry = "";
  let performerPromise = fetchPerformer();

  // Set page and nav titles
  $: metatags.title = title;
  navTitle.set(title);

  async function fetchPerformer() {
    const data = await api.items("performers").readMany({
      filter: {
        slug: {
          _eq: performer,
        },
      },
    });
    title = data["data"][0].name;
    navTitle.set(title);
    performerID = data["data"][0].id;
    performerName = data["data"][0].name;
    performerAddressLocality = data["data"][0].addresslocality;
    performerAddressCountry = data["data"][0].addresscountry;
    return data["data"][0];
  }

  let countriesPromise = fetchCountries();
  async function fetchCountries() {
    const data = await api.transport.axios.get(
      "/fields/performers/addresscountry"
    );
    return [{ value: "US", text: "United States" }].concat(
      data.data.data.meta.options.choices
    );
  }

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  function updateNameFormSubmit(event) {
    // calculate new slug
    // update user with the new slug
    let performerSlug = slug(
      performerName +
        "-" +
        performerAddressLocality +
        "-" +
        performerAddressCountry
    );

    api.items("performers").updateOne(performerID, {
      addresslocality: performerAddressLocality,
      addresscountry: performerAddressCountry,
      slug: performerSlug,
    });
    api.users.me
      .update({ performer: performerSlug })
      .then((result) => {
        user.set(result);
        dialogError.message = "Changes saved";
        messageType = "";
        $goto("/performers/" + performerSlug);
      })
      .catch((error) => {
        showDialog = true;
        dialogError.message = error.message;
      });
  }
</script>

{#await performerPromise then performerObject}
  {#if performerObject.user_created == $user.id}
    <form on:submit|preventDefault class="container m-auto mt-12 md:max-w-sm">
      <div class="text-lg ml-6 mb-6">Update city and country</div>

      <div class="px-6">
        <TextField
          label="City"
          required="true"
          outlined
          prepend="location_city"
          bind:value={performerAddressLocality}
        />

        {#await countriesPromise then countries}
          <Select
            bind:value={performerAddressCountry}
            label="Country"
            required="true"
            outlined
            items={countries}
            listClasses="z-50 fixed overflow-y-auto top-0 left-0 w-full h-full bg-black"
          />
        {/await}
      </div>

      <div class="text-center px-6 mt-16">
        <Button on:click={updateNameFormSubmit} class="rounded-full"
          >Save</Button
        >
        <Button text href={"/performers/" + performer}>Cancel</Button>
      </div>
    </form>

    <Dialog
      bind:value={showDialog}
      opacity="0.85"
      class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
    >
      <div class="{messageType} text-center">{dialogError.message}</div>
      <div slot="actions">
        <Button text href="/user/account" color="white">OK</Button>
      </div>
    </Dialog>
  {/if}
{:catch error}
  <div class="e404">
    {#if error.message == "data.data[0] is undefined"}
      <div class="mb-24 text-error-500">Page not found</div>
      {#if !$user}
        <Button
          href="/user/login?destination={window.location.pathname}"
          block
          outlined
        >
          Log in
        </Button>
      {:else}
        <div>
          <Button href="/" block outlined>HOME</Button>
        </div>
      {/if}
    {:else}
      <p class="text-error-500">{error.message}</p>
    {/if}
  </div>
{/await}
