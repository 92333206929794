<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button, Dialog, List, TextField } from "smelte";
  import { api, authenticatedRole } from "../../lib/api";
  import { user } from "./auth.js";

  // Redirect to homepage if already logged in
  if ($user) {
    $goto("/");
  }

  // Set page and nav titles
  $: metatags.title = "Sign up • NightLife.FM";
  navTitle.set("NightLife.FM");

  let signupFormState = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  function signupFormSubmit(event) {
    api.transport.axios
      .post("/users/invite", {
        email: signupFormState.email.trim(),
        role: authenticatedRole,
        invite_url: __config.env.WEB_URL + "/user/complete-signup",
      })
      .then(function () {
        showDialog = true;
        dialogError.message = "Please check your email";
        messageType = "";
      })
      .catch((error) => {
        showDialog = true;
        dialogError.message = error.message;
      });
  }
</script>

<form on:submit|preventDefault class="container m-auto mt-12 md:max-w-sm ">
  <div class="text-lg ml-6 mb-6">Sign up</div>

  <div class="px-6">
    <TextField type="email" label="Email" required="true" outlined prepend="mail" bind:value={signupFormState.email} />
  </div>

  <div class="px-6 mt-10">
    <Button on:click={signupFormSubmit} block class="rounded-full py-3">Sign up</Button>
    <List
      items={[
        { text: "Log in", to: "/user/login" },
        { text: "Forgot password?", to: "/user/request-password" },
      ]}
      dense
      classes="mt-16 -ml-3"
    />
  </div>
</form>

<Dialog bind:value={showDialog} opacity="0.85" class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4">
  <div class="{messageType} text-center">{dialogError.message}</div>
  <div slot="actions">
    <Button text color="white" on:click={() => (showDialog = false)}>OK</Button>
  </div>
</Dialog>
