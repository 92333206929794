<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle, days } from "../../../lib/store.js";
  import { Button, Dialog, Select } from "smelte";
  import { api } from "../../../lib/api";
  import { user } from "../../user/auth.js";

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  export let performer;

  let title = "NightLife.FM";
  let performerID = null;
  let performerOwner = null;
  let venues = [{ value: null, text: "" }];
  let performerPromise = fetchPerformer();
  let venuesPromise = fetchVenues();

  // Set page and nav titles
  $: metatags.title = title;
  navTitle.set(title);
  let residencyDays = days;

  async function fetchPerformer() {
    const data = await api.items("performers").readMany({
      filter: {
        slug: {
          _eq: performer,
        },
      },
    });
    title = data["data"][0].name;
    navTitle.set(title);
    performerID = data["data"][0].id;
    performerOwner = data["data"][0].user_created;
    residencyDays.forEach((venue) => {
      residencyDays[venue.num].value =
        data["data"][0]["residency_day_" + venue.num];
    });
    return data["data"][0];
  }

  async function fetchVenues() {
    const data = await api.items("venues").readMany({
      fields: [
        "id",
        "name",
        "addresslocality",
        "addressregion",
        "addresscountry",
      ],
      sort: "name",
      limit: -1,
    });
    data.data.forEach((venue) => {
      venues.push({
        value: venue.id,
        text:
          venue.name +
          ", " +
          venue.addresslocality +
          ", " +
          venue.addressregion +
          ", " +
          venue.addresscountry.toUpperCase(),
      });
    });
    return data.data;
  }

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  function updateResidencyFormSubmit(event) {
    api
      .items("performers")
      .updateOne(performerID, {
        residency_day_0: residencyDays[0].value,
        residency_day_1: residencyDays[1].value,
        residency_day_2: residencyDays[2].value,
        residency_day_3: residencyDays[3].value,
        residency_day_4: residencyDays[4].value,
        residency_day_5: residencyDays[5].value,
        residency_day_6: residencyDays[6].value,
      })
      .then((result) => {
        window.history.back();
      })
      .catch((error) => {});
  }
</script>

{#await Promise.all( [performerPromise, venuesPromise] ) then [performerObject, venuesObject]}
  {#if performerOwner == $user.id}
    <form on:submit|preventDefault class="container m-auto md:max-w-sm">
      <div class="text-lg mt-4 ml-6">Update residency</div>
      <div class="px-6 text-sm">
        {#each residencyDays as day}
          <Select
            bind:value={day.value}
            outlined
            autocomplete
            label={day.label}
            items={venues}
          />
        {/each}
      </div>

      <div class="text-center px-6 mt-4">
        <Button on:click={updateResidencyFormSubmit} class="rounded-full"
          >Save</Button
        >
        <Button text href={"/performers/" + performer}>Cancel</Button>
      </div>
    </form>

    <Dialog
      bind:value={showDialog}
      opacity="0.85"
      class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
    >
      <div class="{messageType} text-center">{dialogError.message}</div>
      <div slot="actions">
        <Button text href="/user/account" color="white">OK</Button>
      </div>
    </Dialog>
  {/if}
{:catch error}
  <div class="e404">
    {#if error.message == "data.data[0] is undefined"}
      <div class="mb-24 text-error-500">Page not found</div>
      {#if !$user}
        <Button
          href="/user/login?destination={window.location.pathname}"
          block
          outlined
        >
          Log in
        </Button>
      {:else}
        <div>
          <Button href="/" block outlined>HOME</Button>
        </div>
      {/if}
    {:else}
      <p class="text-error-500">{error.message}</p>
    {/if}
  </div>
{/await}
