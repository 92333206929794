<script>
  import { goto, metatags } from "@sveltech/routify";
  import { days, genres, navTitle } from "../../../lib/store.js";
  import { Button, Dialog, List, Snackbar, TextField } from "smelte";
  import { api, getEventsDateFilter, getMapboxImageURL, getDirectionsURL } from "../../../lib/api";
  import { user } from "../../user/auth.js";
  import WaveSurfer from "wavesurfer.js";

  export let venue;

  let title = "NightLife.FM";
  let venueID = null;
  let venuePromise = fetchVenue();
  let residentsPromise = null;
  let tonightEventsPromise = null;
  let futureEventsPromise = null;
  let pastEventsPromise = null;
  let residents_exist = false;
  let residentDays = [];
  let imgURL = "";
  let directionsURL = "";
  let shareURL = (document.querySelector("link[rel=canonical]") && document.querySelector("link[rel=canonical]").href) || window.location.href;

  $: hasLiked = false;
  $: likeID = null;
  $: likesCount = "";
  $: sharesCount = "";
  $: menuList = [];

  // Set page and nav titles
  $: metatags.title = title;
  navTitle.set(title);

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";
  let showDialogShare = false;
  let showSnackbar = false;
  let showDialogActions = false;

  $: wavesurfer = null;

  async function fetchVenue() {
    const data = await api.items("venues").readMany({
      filter: {
        slug: {
          _eq: venue,
        },
      },
      fields: ["*"],
    });
    title = data["data"][0].name;
    navTitle.set(title);
    venueID = data["data"][0].id;
    imgURL = getMapboxImageURL(data["data"][0]);
    directionsURL = getDirectionsURL(data["data"][0]);
    fetchLikes(venueID);
    fetchShares(venueID);
    residentsPromise = fetchResidents(venueID);
    tonightEventsPromise = fetchEvents("tonight");
    futureEventsPromise = fetchEvents("future");
    pastEventsPromise = fetchEvents("past");

    // The following produces an error
    // if ($user != undefined) {
    //   const data1 = await api.items("performers").readMany({
    //     filter: {
    //       slug: {
    //         _eq: $user.performer,
    //       },
    //     },
    //     fields: ["id"],
    //   });
    //   menuList.push({
    //     text: "Add event",
    //     icon: "add_circle",
    //     to: "/events/add?performer=" + data1["data"][0].id,
    //   });
    // }

    if (data["data"][0].streetaddress != undefined) {
      menuList.push({
        text: data["data"][0].streetaddress,
        icon: "directions",
        to: directionsURL,
      });
    }
    if (data["data"][0].telephone != undefined) {
      menuList.push({
        text: data["data"][0].telephone,
        icon: "call",
        to: "tel: " + data["data"][0].telephone,
      });
    }
    if (data["data"][0].website != undefined) {
      menuList.push({
        text: data["data"][0].website,
        icon: "public",
        to: data["data"][0].website,
      });
    }

    return data["data"][0];
  }

  async function fetchLikes(venueID) {
    // Get the number of likes
    const data1 = await api.items("venues_liked").readMany({
      filter: {
        venue: {
          _eq: venueID,
        },
      },
      limit: 1,
      meta: "*",
      fields: ["id"],
    });
    likesCount = data1.meta.filter_count.toLocaleString();

    // Check if the user has already liked the venue
    if ($user) {
      const data2 = await api.items("venues_liked").readMany({
        filter: {
          venue: {
            _eq: venueID,
          },
          user_created: {
            _eq: $user.id,
          },
        },
        limit: 1,
        fields: ["id"],
      });
      hasLiked = data2["data"].length ? true : false;
      likeID = data2["data"].length ? data2["data"][0].id : null;
    }
  }

  async function fetchShares(venueID) {
    // Get the number of shares
    const data = await api.items("venues_shared").readMany({
      filter: {
        venue: {
          _eq: venueID,
        },
      },
      limit: 1,
      meta: "*",
      fields: ["id"],
    });
    sharesCount = data.meta.filter_count.toLocaleString();
  }

  async function fetchResidents(venueID) {
    const data = await api.items("performers").readMany({
      filter: {
        _or: [
          {
            residency_day_0: {
              _eq: venueID,
            },
          },
          {
            residency_day_1: {
              _eq: venueID,
            },
          },
          {
            residency_day_2: {
              _eq: venueID,
            },
          },
          {
            residency_day_3: {
              _eq: venueID,
            },
          },
          {
            residency_day_4: {
              _eq: venueID,
            },
          },
          {
            residency_day_5: {
              _eq: venueID,
            },
          },
          {
            residency_day_6: {
              _eq: venueID,
            },
          },
        ],
      },
      fields: ["*", "image.*", "track.*"],
    });
    // Sort by residency day
    let residencies = [];
    days.forEach((day, i) => {
      data["data"].forEach((residency, j) => {
        if (data["data"][j] && data["data"][j]["residency_day_" + i] && data["data"][j]["residency_day_" + i] == venueID) {
          // Set Resident Days
          for (var k = 0; k <= 6; k++) {
            if (data["data"][j]["residency_day_" + k] && data["data"][j]["residency_day_" + k] == venueID) {
              if (residentDays[data["data"][j].id] == undefined) {
                residentDays[data["data"][j].id] = [days[k].label];
              } else {
                residentDays[data["data"][j].id].push(days[k].label);
              }
            }
          }

          // Set Performer data, only published ones as filters do not support it
          if (data["data"][j].status == "published") {
            residencies.push(data["data"][j]);
          }

          // Prevent Performers from appearing multiple times
          data["data"][j] = null;
        }
      });
    });

    if (residencies.length != 0) {
      residents_exist = true;
    }
    return residencies;
  }

  async function fetchEvents(eventType) {
    let dateFilter = getEventsDateFilter(eventType);
    let sort = "startdate";
    let dateOptions = {
      month: "short",
      day: "numeric",
    };

    if (eventType == "past") {
      sort = "-startdate";
      // dateOptions.year = "numeric";
    }

    // Fetch from the API
    const data = await api.items("events").readMany({
      filter: {
        _and: [
          {
            venue: {
              _eq: venueID,
            },
          },
          {
            status: {
              _eq: "published",
            },
          },
          { startdate: dateFilter },
        ],
      },
      sort: sort,
      fields: ["*", "performers.*"],
    });
    if (data["data"].length == 0) {
      return;
    }
    // Format date
    data["data"].forEach((event, i) => {
      let date = new Date(event.startdate);
      data["data"][i]["startDate0"] = date.toISOString();
      data["data"][i]["startDate1"] = new Intl.DateTimeFormat("en-US", {
        weekday: "short",
      }).format(date);
      data["data"][i]["startDate2"] = new Intl.DateTimeFormat("en-US", dateOptions).format(date);
      data["data"][i]["startDate3"] = new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        hour12: true,
      })
        .format(date)
        .replace(/\s/g, "");
    });

    // Extract Performer IDs
    let performerIDs = [];
    data["data"].forEach((event, i) => {
      event.performers.forEach((p) => {
        if (p.performers_id !== null) {
          performerIDs.push(p.performers_id);
        }
      });
    });

    if (performerIDs.length == 0) {
      return;
    }

    // Fetch Performers with images and tracks
    const data1 = await api.items("performers").readMany({
      filter: {
        _and: [
          {
            status: {
              _eq: "published",
            },
          },
          {
            id: {
              _in: performerIDs,
            },
          },
        ],
      },
      fields: ["*", "image.*", "track.*"],
    });

    // Placeholder for the next step (reassign)
    let eventsPerformers = [];
    data1["data"].forEach((performer, i) => {
      eventsPerformers[performer.id] = performer;
    });

    // Reassign performers to the returned data, clean out deleted performers
    data["data"].forEach((event, i) => {
      event.performers.forEach((p, j) => {
        event.performers[j] = eventsPerformers[event.performers[j].performers_id];
      });
    });

    return data["data"];
  }

  async function like(venueID) {
    // Only let logged in users to like
    if ($user) {
      // the user has not liked the venue yet
      if (!hasLiked) {
        // create a new item in the venues_liked collection
        api
          .items("venues_liked")
          .createOne({
            venue: venueID,
          })
          .then((result) => {
            fetchLikes(venueID);
          })
          .catch((error) => {
            showDialog = true;
            dialogError.message = error.message;
          });
      } else {
        // delete the item in the venues_liked collection
        api
          .items("venues_liked")
          .deleteOne(likeID)
          .then((result) => {
            fetchLikes(venueID);
          })
          .catch((error) => {
            showDialog = true;
            dialogError.message = error.message;
          });
      }
    } else {
      $goto("/user/login?destination=" + window.location.pathname);
    }
  }

  async function share(venueID) {
    // Only let logged in users to share
    if ($user) {
      // If share functionality is supported by the browser (mobile)
      if (navigator.share) {
        navigator
          .share({
            title,
            shareURL,
          })
          .then(() => {
            logShare(venueID);
          })
          .catch((err) => {
            console.log("Couldn't share");
          });
      } else {
        // Share functionality is not supported (desktop)
        showDialogShare = true;
      }
    } else {
      $goto("/user/login?destination=" + window.location.pathname);
    }
  }

  function copyShare(venueID) {
    document.getElementById("venue-actions-share-url").removeAttribute("disabled");
    document.getElementById("venue-actions-share-url").select();
    document.execCommand("copy");
    document.getElementById("venue-actions-share-url").setAttribute("disabled", "disabled");
    logShare(venueID);
    showSnackbar = true;
  }

  async function logShare(venueID) {
    showDialogShare = false;
    api
      .items("venues_shared")
      .createOne({
        venue: venueID,
      })
      .then((result) => {
        fetchShares(venueID);
      })
      .catch((error) => {
        showDialog = true;
        dialogError.message = error.message;
      });
  }
</script>

<div class="md:flex" itemscope itemtype="https://schema.org/LocalBusiness">
  <div class="-mt-16 md:flex-none md:w-96 md:mt-0 md:ml-3 md:mr-3 mb-6 border-b border-white border-opacity-25">
    <div class="container">
      <div class="relative">
        {#await venuePromise then venueObject}
          <meta itemprop="name" content={venueObject.name} />
          <meta itemprop="url" content="/venues/{venueObject.slug}" />
          <div class="relative">
            <img src={imgURL} alt="Map" class="opacity-75 w-full" />

            {#if venueObject.requiredminage != undefined || venueObject.areaserved != undefined || venueObject.addresslocality != undefined}
              <div class="absolute left-0 bottom-11 ml-2 text-xs">
                {#if venueObject.requiredminage != undefined}
                  <span class="px-2 py-1 bg-black bg-opacity-50 float-left clear-both mb-1 rounded">
                    <span class="material-icons text-xs mr-2">pan_tool</span>
                    {venueObject.requiredminage}+
                  </span>
                {/if}
                {#if venueObject.areaserved != undefined}
                  <span class="px-2 py-1 bg-black bg-opacity-50 float-left clear-both mb-1 rounded">
                    <span class="material-icons text-xs mr-2">location_searching</span>
                    <span itemprop="areaServed">{venueObject.areaserved}</span>
                  </span>
                {/if}

                {#if venueObject.addresslocality != undefined && venueObject.addressregion != undefined}
                  <span itemprop="address" itemscope itemtype="https://schema.org/PostalAddress" class="px-2 py-1 bg-black bg-opacity-50 float-left clear-both rounded">
                    <span class="material-icons text-xs mr-2">explore</span>
                    <meta itemprop="streetAddress" content={venueObject.streetaddress} />
                    <span itemprop="addressLocality">{venueObject.addresslocality}</span>,
                    <span itemprop="addressRegion">{venueObject.addressregion}</span>
                    <meta itemprop="postalCode" content={venueObject.postalcode} />
                    <meta itemprop="addressCountry" content={venueObject.addresscountry.toUpperCase()} />
                  </span>
                {/if}
              </div>
            {/if}

            <div class="absolute right-0 bottom-10 mr-2 px-1 md:mt-8 text-right">
              <Button icon="directions" href={directionsURL} class="z-20 bg-black bg-opacity-50 pt-2 pr-2 pb-2 pl-2 mr-0 mb-3 md:mt-16 lg:mr-0" remove="bg-primary-500" />
            </div>
          </div>

          <div class="absolute bottom-0 w-full flex text-center align-middle bg-black bg-opacity-50">
            <div class="flex-1">
              {#if hasLiked}
                <Button on:click={like(venueID)} text add="py-2 w-full">
                  <span class="material-icons align-middle"> favorite </span>
                  <span class="align-middle ml-2 text-xs font-bold">{likesCount}</span>
                </Button>
              {:else}
                <Button on:click={like(venueID)} text color="white" add="py-2 w-full">
                  <span class="material-icons align-middle"> favorite </span>
                  <span class="align-middle ml-2 text-xs font-bold">{likesCount}</span>
                </Button>
              {/if}
            </div>
            <div class="flex-1">
              <Button text color="white" on:click={share(venueID)} add="py-2 w-full">
                <span class="material-icons flipX align-middle"> reply </span>
                <span class="align-middle ml-2 text-xs font-bold">{sharesCount}</span>
              </Button>
            </div>
            <div class="flex-1">
              <Button on:click={() => (showDialogActions = true)} text color="white" add="py-2 w-full">
                <span class="material-icons align-middle"> more_vert </span>
              </Button>
            </div>
          </div>
        {:catch error}
          <div class="e404">
            {#if error.message == "data.data[0] is undefined"}
              <div class="mb-24 text-error-500">Page not found</div>
              {#if !$user}
                <Button href="/user/login?destination={window.location.pathname}" block outlined>Log in</Button>
              {:else}
                <div>
                  <Button href="/" block outlined>HOME</Button>
                </div>
              {/if}
            {:else}
              <p class="text-error-500">{error.message}</p>
            {/if}
          </div>
        {/await}
      </div>
    </div>
  </div>

  <div class="px-3 md:-mt-3 md:w-96">
    {#await tonightEventsPromise then tonightEventsObject}
      {#if tonightEventsObject && tonightEventsObject.length != 0}
        <h4 class="mt-2 uppercase font-bold">Tonight</h4>
        <ul class="mb-6">
          {#each tonightEventsObject as event}
            <li itemscope itemtype="https://schema.org/MusicEvent">
              {#each event.performers as performer}
                {#if performer != undefined}
                  <a href="/performers/{performer.slug}" class="block relative mb-3 rounded-lg border border-gray-900 border-opacity-75 bg-gray-800 bg-opacity-50">
                    <span class="block relative">
                      {#if performer.image}
                        <img
                          src={api._transport._url + "/assets/" + performer.image.id + "?key=medium"}
                          alt={title}
                          width="425"
                          height="425"
                          style="filter: grayscale(100%)"
                          class="block rounded-lg"
                        />
                      {:else}
                        <span class="flex relative" style="width:425px; height:300px;">
                          <span class="m-auto material-icons opacity-50" style="font-size: 8rem;"> headset </span>
                        </span>
                      {/if}
                      <span class="block absolute top-0 left-0 py-1 px-2 mt-2 ml-2 rounded bg-black bg-opacity-75 text-xs leading-tight">
                        {event.startDate1} <br />
                        {event.startDate2} <br />
                        {event.startDate3}
                        <meta itemprop="startDate" content={event.startDate0} />
                      </span>
                    </span>
                    <span itemprop="performer" itemscope itemtype="https://schema.org/MusicGroup">
                      <meta itemprop="url" content="/performers/{performer.slug}" />
                      <span class="block absolute top-2 left-16 mr-2 pt-1 pb-2 px-2 rounded bg-black bg-opacity-75 text-lg leading-none">
                        {performer.name}
                        <meta itemprop="name" content={performer.name} />
                      </span>
                      {#if performer.genres}
                        {#if event.ticketurl}
                          <ul class="block absolute bottom-14 right-2 pl-2 text-right">
                            {#each performer.genres as genre}
                              {#if genres[genre] != undefined}
                                <li class="leading-tight">
                                  <span itemprop="genre" class="bg-black bg-opacity-75 rounded-sm px-1 mb-1 text-sm">{genres[genre]}</span>
                                </li>
                              {/if}
                            {/each}
                          </ul>
                        {:else}
                          <ul class="block absolute bottom-2 right-2 pl-2 text-right">
                            {#each performer.genres as genre}
                              {#if genres[genre] != undefined}
                                <li class="leading-tight">
                                  <span itemprop="genre" class="bg-black bg-opacity-75 rounded-sm px-1 mb-1 text-sm">{genres[genre]}</span>
                                </li>
                              {/if}
                            {/each}
                          </ul>
                        {/if}
                      {/if}
                    </span>
                  </a>
                  {#if event.ticketurl}
                    <a itemprop="offers" class="block absolute w-full bottom-0 left-0 py-3 rounded-b-lg bg-primary-600 text-center uppercase font-bold" href={event.ticketurl}>Tickets</a>
                  {/if}
                {/if}
              {/each}
            </li>
          {/each}
        </ul>
      {/if}
    {:catch error}
      <div class="e404">
        <p class="text-error-500">{error.message}</p>
      </div>
    {/await}

    {#await residentsPromise then residentsObject}
      {#if residents_exist}
        <h4 class="mt-2 uppercase font-bold">Residents</h4>
        <ul class="mb-6">
          {#each residentsObject as resident}
            <li itemscope itemtype="https://schema.org/MusicEvent">
              <a href="/performers/{resident.slug}" class="flex relative h-32 mb-3 box-content rounded-lg border border-gray-900 border-opacity-100 bg-gray-900 bg-opacity-50">
                <span class="flex-none w-32 mr-3">
                  {#if resident.image}
                    <img
                      src={api._transport._url + "/assets/" + resident.image.id + "?key=thumbnail"}
                      alt={title}
                      width="128"
                      height="128"
                      style="filter: grayscale(100%)"
                      class="block rounded-l-lg"
                    />
                  {:else}
                    <span class="material-icons align-middle opacity-50" style="font-size: 4rem; padding: 2rem;"> headset </span>
                  {/if}
                  <span class="block absolute top-0 left-0 p-1 mt-2 ml-2 rounded bg-black bg-opacity-75 text-xs leading-tight">
                    {#each residentDays[resident.id] as day}
                      <span itemprop="startDate" class="block mr-1 text-xs">
                        {day}
                      </span>
                    {/each}
                  </span>
                </span>
                <span itemprop="performer" itemscope itemtype="https://schema.org/MusicGroup" class="flex-auto overflow-hidden">
                  <meta itemprop="url" content="/performers/{resident.slug}" />
                  <meta itemprop="name" content={resident.name} />
                  <span class="flex flex-col"> <span class="flex mt-3 mb-2 text-lg font-bold leading-none">{resident.name}</span></span>
                  {#if resident.genres}
                    <div class="hidden">
                      {resident.genres.splice(3)}
                    </div>
                    {#each resident.genres as genre}
                      {#if genres[genre] != undefined}
                        <span itemprop="genre" class="block text-gray-500 text-xs">{genres[genre]}</span>
                      {/if}
                    {/each}
                  {/if}
                </span>
              </a>
            </li>
          {/each}
        </ul>
      {/if}
    {/await}

    {#await futureEventsPromise then futureEventsObject}
      {#if futureEventsObject}
        <h4 class="uppercase font-bold">Upcoming Events</h4>
        <ul class="mb-6">
          {#each futureEventsObject as event}
            <li itemscope itemtype="https://schema.org/MusicEvent">
              {#each event.performers as performer}
                {#if performer != undefined}
                  <a href="/performers/{performer.slug}" class="flex relative h-32 mb-3 box-content rounded-lg border border-gray-900 border-opacity-75 bg-gray-900 bg-opacity-50">
                    <span class="flex-none mr-4">
                      {#if performer.image}
                        {#if event.ticketurl}
                          <img
                            src={api._transport._url + "/assets/" + performer.image.id + "?key=thumbnail"}
                            alt={title}
                            width="128"
                            height="128"
                            style="filter: grayscale(100%)"
                            class="block rounded-tl-lg"
                          />
                        {:else}
                          <img
                            src={api._transport._url + "/assets/" + performer.image.id + "?key=thumbnail"}
                            alt={title}
                            width="128"
                            height="128"
                            style="filter: grayscale(100%)"
                            class="block rounded-l-lg"
                          />
                        {/if}
                      {:else}
                        <span class="material-icons align-middle opacity-50" style="font-size: 4rem; padding: 2rem;"> headset </span>
                      {/if}
                      <span class="block absolute top-0 left-0 p-1 mt-2 ml-2 rounded bg-black bg-opacity-75 text-xs leading-tight">
                        {event.startDate1} <br />
                        {event.startDate2} <br />
                        {event.startDate3}
                        <meta itemprop="startDate" content={event.startDate0} />
                      </span>
                    </span>
                    <span itemprop="performer" itemscope itemtype="https://schema.org/MusicGroup" class="flex-auto overflow-hidden">
                      <meta itemprop="url" content="/performers/{performer.slug}" />
                      <span class="flex flex-col"><span class="flex mt-3 mb-2 text-lg font-bold leading-none">{performer.name}</span></span>
                      <meta itemprop="name" content={performer.name} />
                      {#if performer.genres}
                        <div class="hidden">
                          {performer.genres.splice(3)}
                        </div>
                        {#each performer.genres as genre}
                          {#if genres[genre] != undefined}
                            <span itemprop="genre" class="block text-gray-500 text-xs">{genres[genre]}</span>
                          {/if}
                        {/each}
                      {/if}
                    </span>
                  </a>
                {/if}
              {/each}
              {#if event.ticketurl}
                <a itemprop="offers" class="block relative py-2 rounded-b-lg bg-primary-600 text-center text-xs uppercase font-bold -mt-3 mb-3" href={event.ticketurl}>Tickets</a>
              {/if}
            </li>
          {:else}
            <div class="text-gray-500">No upcoming events found.</div>
          {/each}
        </ul>
      {/if}
    {:catch error}
      <div class="e404">
        <p class="text-error-500">{error.message}</p>
      </div>
    {/await}

    {#await pastEventsPromise then pastEventsObject}
      {#if pastEventsObject}
        <h4 class="uppercase font-bold">Past Events</h4>
        <ul class="mb-6">
          {#each pastEventsObject as event}
            <li itemscope itemtype="https://schema.org/MusicEvent">
              {#each event.performers as performer}
                {#if performer != undefined}
                  <a href="/performers/{performer.slug}" class="flex relative h-32 mb-3 rounded-lg border border-gray-900 border-opacity-75 bg-gray-900 bg-opacity-50">
                    <span class="flex-none w-32 mr-4">
                      {#if performer.image}
                        <img
                          src={api._transport._url + "/assets/" + performer.image.id + "?key=thumbnail"}
                          alt={title}
                          width="128"
                          height="128"
                          style="filter: grayscale(100%)"
                          class="block rounded-l-lg"
                        />
                      {:else}
                        <span class="material-icons align-middle opacity-50" style="font-size: 4rem; padding: 2rem;"> headset </span>
                      {/if}
                      <span class="block absolute top-0 left-0 p-1 mt-2 ml-2 rounded bg-black opacity-75 text-xs leading-tight">
                        {event.startDate1} <br />
                        {event.startDate2} <br />
                        {event.startDate3}
                        <meta itemprop="startDate" content={event.startDate0} />
                      </span>
                    </span>
                    <span itemprop="performer" itemscope itemtype="https://schema.org/MusicGroup" class="flex-auto overflow-hidden">
                      <meta itemprop="url" content="/performers/{performer.slug}" />
                      <span class="flex mt-3 mb-2 text-lg font-bold leading-none">{performer.name}</span>
                      <meta itemprop="name" content={performer.name} />
                      {#if performer.genres}
                        <div class="hidden">
                          {performer.genres.splice(3)}
                        </div>
                        {#each performer.genres as genre}
                          {#if genres[genre] != undefined}
                            <span itemprop="genre" class="block text-gray-500 text-xs">{genres[genre]}</span>
                          {/if}
                        {/each}
                      {/if}
                    </span>
                  </a>
                {/if}
              {/each}
            </li>
          {:else}
            <div class="text-gray-500">No past events found.</div>
          {/each}
        </ul>
      {/if}
    {:catch error}
      <div class="e404">
        <p class="text-error-500">{error.message}</p>
      </div>
    {/await}
  </div>
</div>

<Dialog bind:value={showDialog} opacity="0.85" class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4">
  <div class="{messageType} text-center">{dialogError.message}</div>
  <div slot="actions">
    <Button text on:click={() => (showDialog = false)} color="white" class="hover:bg-gray-transDark" icon="cancel" iconClass="mr-3">Cancel</Button>
  </div>
</Dialog>

<!-- Share Venue Dialog -->
<Dialog bind:value={showDialogShare} opacity="0.85" class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4">
  <h5 slot="title">Copy to clipboard</h5>
  <TextField disabled textarea outlined value={shareURL} rows="2" id="venue-actions-share-url" class="text-white text-xs font-bold bg-gray-900" />
  <div slot="actions">
    <Button on:click={() => copyShare(venueID)}>Copy</Button>
    <Button on:click={() => (showDialogShare = false)} text color="white">Close</Button>
  </div>
</Dialog>

<Snackbar bind:value={showSnackbar}>
  <div>Link has been copied to clipboard</div>
</Snackbar>

<!-- More Actions Dialog -->
<Dialog bind:value={showDialogActions} navigation="true" opacity="0.9" class="ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4">
  <div slot="title" class="pl-4 font-normal">{title}</div>
  <!-- binding to List component event did not work, using div -->
  <div on:click={() => (showDialogActions = false)}>
    <List items={menuList} />
  </div>
  <div slot="actions">
    <Button on:click={() => (showDialogActions = false)} text color="white" class="hover:bg-gray-transDark" icon="cancel" iconClass="mr-3">Cancel</Button>
  </div>
</Dialog>

<style>
  .e404 {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .flipX {
    transform: scaleX(-1);
  }
</style>
