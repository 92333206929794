<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../../lib/store.js";
  import { Button } from "smelte";
  import { api } from "../../../lib/api";
  import { user } from "../../user/auth.js";

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  export let performer;

  let title = "NightLife.FM";
  let performerID = null;
  let performerName = "";
  let performerAddressLocality = "";
  let performerAddressCountry = "";
  let performerPromise = fetchPerformer();
  let today = new Date();
  let today_7 = new Date(new Date().setDate(new Date().getDate() - 7));
  let today_14 = new Date(new Date().setDate(new Date().getDate() - 14));
  let today_30 = new Date(new Date().setDate(new Date().getDate() - 30));
  let today_60 = new Date(new Date().setDate(new Date().getDate() - 60));

  today    = today.toISOString();
  today_7  = today_7.toISOString();
  today_14 = today_14.toISOString();
  today_30 = today_30.toISOString();
  today_60 = today_60.toISOString();

  // Set page and nav titles
  $: metatags.title = title;
  navTitle.set(title);

  async function fetchPerformer() {
    const data = await api.items("performers").readMany({
      filter: {
        slug: {
          _eq: performer,
        },
      },
    });
    title = data["data"][0].name;
    navTitle.set(title);
    performerID = data["data"][0].id;
    performerName = data["data"][0].name;
    performerAddressLocality = data["data"][0].addresslocality;
    performerAddressCountry = data["data"][0].addresscountry;

    let stats = {};

    stats["viewed"] = [];
    stats["viewed"]["_7"]   = await fetchStats("performers_viewed",performerID,today_7,today);
    stats["viewed"]["_14"]  = await fetchStats("performers_viewed",performerID,today_14,today_7);
    stats["viewed"]["_30"]  = await fetchStats("performers_viewed",performerID,today_30,today);
    stats["viewed"]["_60"]  = await fetchStats("performers_viewed",performerID,today_60,today_30);

    stats["played"] = [];
    stats["played"]["_7"]   = await fetchStats("performers_played",performerID,today_7,today);
    stats["played"]["_14"]  = await fetchStats("performers_played",performerID,today_14,today_7);
    stats["played"]["_30"]  = await fetchStats("performers_played",performerID,today_30,today);
    stats["played"]["_60"]  = await fetchStats("performers_played",performerID,today_60,today_30);

    stats["liked"] = [];
    stats["liked"]["_7"]   = await fetchStats("performers_liked",performerID,today_7,today);
    stats["liked"]["_14"]  = await fetchStats("performers_liked",performerID,today_14,today_7);
    stats["liked"]["_30"]  = await fetchStats("performers_liked",performerID,today_30,today);
    stats["liked"]["_60"]  = await fetchStats("performers_liked",performerID,today_60,today_30);

    stats["shared"] = [];
    stats["shared"]["_7"]   = await fetchStats("performers_shared",performerID,today_7,today);
    stats["shared"]["_14"]  = await fetchStats("performers_shared",performerID,today_14,today_7);
    stats["shared"]["_30"]  = await fetchStats("performers_shared",performerID,today_30,today);
    stats["shared"]["_60"]  = await fetchStats("performers_shared",performerID,today_60,today_30);

    let statTypes = ["viewed", "played", "liked", "shared"];
    let statNames = {viewed: "views", played: "plays", liked: "likes", shared: "shares"};
    statTypes.forEach(type => {
      // 7 day stats
      if (stats[type]["_7"] == stats[type]["_14"]) {
        // No change: 2 Views (-)
        stats[type]["_7_text"] = stats[type]["_7"] + " " + statNames[type] + " ( - )";
        stats[type]["_7_class"] = "";
      }
      else if (stats[type]["_7"] > stats[type]["_14"]) {
        // Increase: 862 Views (↑73.19%)
        if (stats[type]["_14"] == 0) { // no stats for previous period
          stats[type]["_7_text"] = stats[type]["_7"] + " " + statNames[type] + " ( ↑- )";
        }
        else {
          stats[type]["_7_text"] = stats[type]["_7"] + " " + statNames[type] +  " (↑" + (100*(stats[type]["_7"] - stats[type]["_14"])/stats[type]["_14"]).toFixed(2) + "%)";
        }
        stats[type]["_7_class"] = "text-success-500";
      }
      else {
        // Decrease: 234 Views (↓34.84%)
        stats[type]["_7_text"] = stats[type]["_7"] + " " + statNames[type] + " (↓" + (100*(stats[type]["_7"] - stats[type]["_14"])/stats[type]["_14"]).toFixed(2) + "%)";
        stats[type]["_7_class"] = "text-error-500";
      }

      // 30 day stats
      if (stats[type]["_30"] == stats[type]["_60"]) {
        // No change: 2 Views (-)
        stats[type]["_30_text"] = stats[type]["_30"] + " " + statNames[type] + " ( - )";
        stats[type]["_30_class"] = "";
      }
      else if (stats[type]["_30"] > stats[type]["_60"]) {
        // Increase: 862 Views (↑73.19%)
        if (stats[type]["_60"] == 0) {
          stats[type]["_30_text"] = stats[type]["_30"] + " " + statNames[type] + " (↑- )";
        }
        else {
          stats[type]["_30_text"] = stats[type]["_30"] + " " + statNames[type] +  " (↑" + (100*(stats[type]["_30"] - stats[type]["_60"])/stats[type]["_60"]).toFixed(2) + "%)";
        }
        stats[type]["_30_class"] = "text-success-500";
      }
      else {
        // Decrease: 234 Views (↓34.84%)
        stats[type]["_30_text"] = stats[type]["_30"] + " " + statNames[type] + " (↓" + (100*(stats[type]["_30"] - stats[type]["_60"])/stats[type]["_60"]).toFixed(2) + "%)";
        stats[type]["_30_class"] = "text-error-500";
      }
    });

    // console.log(stats);
    return { performer: data["data"][0], stats: stats };
  }

  async function fetchStats(statsType, performerID, startDate, endDate) {
    const data = await api.transport.axios.get(
      "/items/" +
        statsType +
        '?filter={"_and": [{"performer":{"_eq":"' +
        performerID +
        '"}}, {"date_created": {"_between": "' +
        startDate +
        "," +
        endDate +
        '"}}]}&meta=*&fields=id&limit=1'
    );
    return data.data.meta.filter_count;
  }
</script>

{#await performerPromise then r}
  {#if r.performer.user_created == $user.id}
    <div class="container mx-3">
      <h4 class="uppercase font-bold clear-both">Weekly Stats</h4>
      <h6 class="clear-both my-3">Profile Page (Last 7 Days)</h6>
      <ul class="mb-6">
        <li class="my-2 {r.stats["viewed"]["_7_class"]}">{r.stats["viewed"]["_7_text"]}</li>
        <li class="my-2 {r.stats["played"]["_7_class"]}">{r.stats["played"]["_7_text"]}</li>
        <li class="my-2 {r.stats["liked"]["_7_class"]}">{r.stats["liked"]["_7_text"]}</li>
        <li class="my-2 {r.stats["shared"]["_7_class"]}">{r.stats["shared"]["_7_text"]}</li>
      </ul>
      <h4 class="uppercase font-bold clear-both">Monthly Stats</h4>
      <h6 class="clear-both my-3">Profile Page (Last 30 Days)</h6>
      <ul class="mb-6">
        <li class="my-2 {r.stats["viewed"]["_30_class"]}">{r.stats["viewed"]["_30_text"]}</li>
        <li class="my-2 {r.stats["played"]["_30_class"]}">{r.stats["played"]["_30_text"]}</li>
        <li class="my-2 {r.stats["liked"]["_30_class"]}">{r.stats["liked"]["_30_text"]}</li>
        <li class="my-2 {r.stats["shared"]["_30_class"]}">{r.stats["shared"]["_30_text"]}</li>
      </ul>
    </div>
  {/if}
{:catch error}
  <div class="e404">
    {#if error.message == "data.data[0] is undefined"}
      <div class="mb-24 text-error-500">Page not found</div>
      {#if !$user}
        <Button
          href="/user/login?destination={window.location.pathname}"
          block
          outlined
        >
          Log in
        </Button>
      {:else}
        <div>
          <Button href="/" block outlined>HOME</Button>
        </div>
      {/if}
    {:else}
      <p class="text-error-500">{error.message}</p>
    {/if}
  </div>
{/await}
