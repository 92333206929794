<script>
  import { metatags } from "@sveltech/routify";
  import { navTitle } from "../../../lib/store.js";
  import { api } from "../../../lib/api";
  import { user } from "../../user/auth.js";

  export let event;
  let title = "NightLife.FM";
  $: metatags.title = title;
  navTitle.set(title);

  let eventID = null;
  $: eventDate = null;
  let eventPromise = fetchEvent(event);

  async function fetchEvent(event) {
    const data = await api.items("events").readMany({
      filter: {
        slug: {
          _eq: event,
        },
      },
      fields: ["*", "venue.*", "performers.performers_id.*"],
    });
    title = "";
    let performerNames = [];
    data["data"][0].performers.forEach((performer) => {
      performerNames.push(performer.performers_id.name);
    });
    title = (
      performerNames.join(", ") +
      " at " +
      data["data"][0].venue.name
    ).substring(0, 28);
    navTitle.set(title);
    eventID = data["data"][0].id;
    eventDate = Intl.DateTimeFormat("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(new Date(data["data"][0].startdate));

    return data["data"][0];
  }
</script>

<div class="container mx-auto h-full items-center p-3">
  {#await eventPromise then eventObject}
    <div>{eventDate}</div>
    <div>{eventObject.venue.name}</div>
    <ul>
      {#each eventObject.performers as performer}
        <li>{performer.performers_id.name}</li>
      {/each}
    </ul>
  {/await}
</div>
