<script>
  import { api } from "../../lib/api";
  import { genres } from "../../lib/store.js";

  let performersPromise = fetchPerformers();

  async function fetchPerformers() {
    const data = await api.items("performers").readMany({
      filter: {
        status: {
          _eq: "published",
        },
      },
      sort: "-date_created",
      limit: 12,
    });

    return data["data"];
  }

  function formatGenres(performerGenres) {
    let formattedGenres = [];
    performerGenres.forEach((genre) => {
      if (genres[genre] != undefined) {
        formattedGenres.push(genres[genre]);
      }
    });
    return formattedGenres.join(", ");
  }
</script>

<div
  class="container bg-gray-900 bg-opacity-50 rounded-lg pt-4 pr-0 pb-3 pl-3 mb-6"
>
  <div class="flex ml-2 mr-4 mb-3">
    <div class="flex-grow mr-3 uppercase text-xs font-bold">
      Latest performers
    </div>
  </div>
  {#await performersPromise then performers}
    <ul class="mr-3 mt-2">
      {#each performers as performer}
        <li class="mb-3">
          <a
            href="/performers/{performer.slug}"
            class="h-16 flex rounded-lg box-border border border-white border-opacity-10 bg-gray-800 bg-opacity-25"
          >
            <span class="flex-none w-16 mr-3">
              {#if performer.image}
                <img
                  src={api._transport._url +
                    "/assets/" +
                    performer.image +
                    "?key=thumbnail"}
                  alt={performer.name}
                  width="64"
                  height="64"
                  style="filter: grayscale(100%)"
                  class="block rounded-l-lg"
                />
              {:else}
                <span
                  class="material-icons align-middle opacity-50"
                  style="font-size: 2rem; padding: 1rem"
                >
                  headset
                </span>
              {/if}
            </span>
            <span class="flex-auto overflow-hidden align-top">
              <span class="flex flex-col mt-2">
                <span class="text-lg font-bold truncate"
                  >{performer.name}</span
                >
                <span class="block truncate opacity-75 text-xs -mt-1">
                  {#if performer.addresslocality && performer.addresscountry}
                    {performer.addresslocality}, {performer.addresscountry} /
                  {/if}
                  {#if performer.genres}
                    {formatGenres(performer.genres)}
                  {/if}
                </span>
              </span>
            </span></a
          >
        </li>
      {/each}
      <li>
        <a
          href="/performers/all"
          class="block rounded-lg border border-white border-opacity-10 bg-gray-800 bg-opacity-25"
          style="height: 64px"
        >
          <span class="block mt-5 text-center uppercase text-xs font-bold"
            >See all performers</span
          >
        </a>
      </li>
    </ul>
  {/await}
</div>
