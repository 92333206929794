<script>
  import { api } from "../../lib/api";
  import { genreGroups } from "../../lib/store.js";
  import Events from "./Events.svelte";

  export let venuesFilter;

  $: eventsPromise = fetchEvents(venuesFilter);
  async function fetchEvents(venuesFilter) {
    let dateFilter;
    if (false) {
      // Show events for tonight even after midnight
      dateFilter = getEventsDateFilter("tonight");
    } else {
      let d = new Date(venuesFilter.date);
      let start =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " 00:00:00";

      let end =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " 23:59:59";

      dateFilter = {
        _between: start + "," + end,
      };
    }

    let hoodsFilter = [];
    if (venuesFilter.neighborhoods[0] !== "Any Neighborhood") {
      venuesFilter.neighborhoods.forEach((hood) => {
        hoodsFilter.push({
          venue: {
            areaserved: {
              _contains: hood,
            },
          },
        });
      });
      hoodsFilter = {
        _or: hoodsFilter,
      };
    }

    const events = await api.items("events").readMany({
      filter: {
        _and: [
          {
            status: {
              _eq: "published",
            },
          },
          {
            startdate: dateFilter,
          },
          {
            venue: {
              metro: {
                _eq: venuesFilter.metro.id,
              },
            },
          },
          hoodsFilter,
        ],
      },
      fields: ["*", "venue.*", "performers.*.*"],
    });

    // Group events by genre
    let eventGroups = [];

    genreGroups.forEach(() => {
      eventGroups.push([]);
    });

    events.data.forEach((event, i) => {
      event.performers.forEach((performer, j) => {
        if (performer.performers_id !== null) {
          performer.performers_id.genres.forEach((genre, k) => {
            if (genre) {
              genreGroups.forEach((group, g) => {
                if (genre in group) {
                  // Add only once
                  if (!eventGroups[g].find(({ id }) => id === event.id)) {
                    eventGroups[g].push(event);
                  }
                }
              });
            }
          });
        }
      });
    });

    return eventGroups;
  }
</script>

{#await eventsPromise then data}
  {#each data as eventGroup, g}
    <Events
      data={{ events: eventGroup, title: Object.values(genreGroups[g])
          .join(', '), size: 7 }} />
  {/each}
{/await}
