<script>
  import { goto, metatags, params } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { api } from "../../lib/api";

  // Set page and nav titles
  $: metatags.title = "All Venues • NightLife.FM";
  navTitle.set("All Venues");

  let metro;
  let venuesPromise = fetchVenues();

  async function fetchVenues() {
    const data0 = await api
      .items("cities")
      .readMany({
        filter: {
          status: {
            _eq: "published",
          },
        },
        sort: "name",
        fields: ["*"],
      })
      .then((data0) => {
        let metros = data0.data;
        metro = metros.find(
          ({ slug }) =>
            slug === ($params.metro ? $params.metro : "washington-dc")
        );
        if (!metro) {
          $goto("/404")
        }
      });

    const data = await api.items("venues").readMany({
      filter: {
        _and: [
          {
            status: {
              _eq: "published",
            },
          },
          {
            metro: {
              _eq: metro.id,
            },
          },
        ],
      },
      sort: "name",
      limit: -1,
      fields: ["name", "areaserved", "slug"],
    });

    return data["data"];
  }
</script>

<div class="container px-3 md:-mt-3 md:w-96">
  {#await venuesPromise then venues}
    <h4 class="mt-2 mb-1 uppercase font-bold">{metro.name}</h4>
    <ul>
      {#each venues as venue}
        <li class="mb-3">
          <a
            href="/venues/{venue.slug}"
            class="block rounded-lg py-3 px-4 border border-gray-800 border-opacity-50 bg-gray-800 bg-opacity-25 "
          >
            <span class="block font-bold">{venue.name}</span>
            {#if venue.areaserved}
              <span class="block text-xs text-gray-500">{venue.areaserved.join(", ")}</span>
            {:else}<span class="block text-xs">&nbsp;</span>{/if}
          </a>
        </li>
      {/each}
    </ul>
  {/await}
</div>
