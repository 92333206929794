<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button, Dialog, List } from "smelte";
  import { user } from "./auth.js";
  import { api } from "../../lib/api";

  // Set page and nav titles
  $: metatags.title = "Update Account • NightLife.FM";
  navTitle.set("NightLife.FM");

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  let menuList = [];
  let menuList1 = [
    {
      text: $user.first_name + " " + $user.last_name,
      icon: "account_circle",
      to: "/user/update-name",
    },
    {
      text: $user.email,
      icon: "email",
      to: "/user/update-email",
    },
    {
      text: "Password",
      icon: "lock",
      to: "/user/update-password",
    },
  ];

  if (!$user.performer) {
    menuList = menuList1.concat([
      {
        text: "Register as a performer",
        icon: "headset",
        to: "/user/register-as-performer",
      },
    ]);
  } else {
    menuList = menuList1.concat([
      {
        text: "Performer profile",
        icon: "headset",
        to: "/performers/" + $user.performer,
      },
    ]);
  }
</script>

<div class="container">
  <div class="text-lg mt-6 mx-4">Update Account</div>
  <List items={menuList} />
</div>
