<script>
  import { metatags } from "@sveltech/routify";
  import { navTitle } from "..//lib/store.js";
  import Button from "smelte/src/components/Button";

  // Set page title, do not index for search
  $: {
      metatags.title = "404 • Page Not Found • NightLife.FM";
      metatags.robots = "noindex";
  }

  // Set nav title
  navTitle.set('NightLife.FM');

  // Redirect to /404/ path for proper HTTP response code
  if (!window.location.pathname.startsWith("/404")) {
    window.location.pathname = "/404" + window.location.pathname;
  }
</script>

<style>
  .huge {
    font-size: 12rem;
  }
  .e404 {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
</style>

<div class="e404">
  <div class="huge">404</div>
  <div class="big mb-24">Page not found.</div>
  <div><Button href="/" block outlined>HOME</Button></div>
</div>
