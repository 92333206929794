<script>
  import { Checkbox, TextField } from "smelte";
  import { genres } from "../lib/store.js";

  export let showGenres = false;
  export let value;
  export let initialValue;

  let formGenres = [];
  let selectedGenres = [];

  for (const genre in genres) {
    formGenres.push({
      value: genre,
      text: genres[genre],
    });
  }

  if (initialValue) {
    formGenres.forEach((genre) => {
      if (initialValue.includes(genre.value)) {
        selectedGenres.push(genre);
      }
    });
  }

  $: selectedGenresLabel = selectedGenres.map((i) => i.text).join(", ");
  $: value = selectedGenres.map((i) => i.value);

  function toggle(i) {
    return (v) =>
      (selectedGenres = v.detail
        ? selectedGenres.concat(i)
        : selectedGenres.filter((si) => si !== i));
  }

  function hideGenres() {
    showGenres = false;
  }

  function cancelGenres() {
    window.history.back();
  }
</script>

<div class="container">
  <ul class="checkboxes mt-3 whitespace-nowrap" style="column-count: 2;">
    {#each formGenres as genre}
      <li class="w-1/2">
        <Checkbox
          checked={selectedGenres.includes(genre)}
          classes="inline-flex items-center cursor-pointer z-10 text-xs whitespace-no-wrap"
          label={genre.text}
          on:change={toggle(genre)}
        />
      </li>
    {/each}
  </ul>
</div>

<div class="genresInput hidden">
  <TextField
    bind:value={selectedGenresLabel}
    label="Genres"
    required="true"
    outlined
    prepend="music_note"
  />
</div>
