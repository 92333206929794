<script>
  import { goto, metatags } from "@sveltech/routify";
  import { days, genres, navTitle } from "../../../lib/store.js";
  import { Button, Dialog, List, Snackbar, TextField } from "smelte";
  import { api, getEventsDateFilter, getMapboxImageURL, getDirectionsURL } from "../../../lib/api";
  import { user } from "../../user/auth.js";
  import WaveSurfer from "wavesurfer.js";

  export let performer;

  let title = "NightLife.FM";
  let performerID = null;
  let trackID = null;
  let performerPromise = fetchPerformer();
  let tonightEventsPromise = null;
  let futureEventsPromise = null;
  let pastEventsPromise = null;
  let residency_exists = false;
  let shareURL = (document.querySelector("link[rel=canonical]") && document.querySelector("link[rel=canonical]").href) || window.location.href;

  $: playsCount = "";
  $: hasLiked = false;
  $: likeID = null;
  $: likesCount = "";
  $: sharesCount = "";

  // Set page and nav titles
  $: metatags.title = title;
  navTitle.set(title);

  let showDialog = false;
  let showDialogShare = false;
  let showSnackbar = false;
  $: menuList = [
    {
      text: "Add event",
      icon: "add_circle",
      to: "/events/add?performer=" + performerID,
    },
    {
      text: "Stage name",
      icon: "account_circle",
      to: "/performers/" + performer + "/update-name",
    },
    {
      text: "Genre",
      icon: "music_note",
      to: "/performers/" + performer + "/update-genre",
    },
    {
      text: "City & Country",
      icon: "location_city",
      to: "/performers/" + performer + "/update-city-country",
    },
    {
      text: "Image",
      icon: "image",
      to: "/performers/" + performer + "/update-image",
    },
    {
      text: "Track",
      icon: "bar_chart",
      to: "/performers/" + performer + "/update-track",
    },
    {
      text: "Residency",
      icon: "home",
      to: "/performers/" + performer + "/update-residency",
    },
  ];

  $: wavesurfer = null;

  async function fetchPerformer() {
    const data = await api.items("performers").readMany({
      filter: {
        slug: {
          _eq: performer,
        },
      },
      fields: ["*", "residency_day_0.*", "residency_day_1.*", "residency_day_2.*", "residency_day_3.*", "residency_day_4.*", "residency_day_5.*", "residency_day_6.*"],
    });
    title = data["data"][0].name;
    navTitle.set(title);
    performerID = data["data"][0].id;
    logView(performerID);
    trackID = data["data"][0].track;
    days.forEach((day) => {
      if (data["data"][0]["residency_day_" + day.num] != undefined) {
        residency_exists = true;
      }
    });
    if (trackID) {
      fetchPlays(performerID);
    }
    fetchLikes(performerID);
    fetchShares(performerID);
    tonightEventsPromise = fetchEvents("tonight");
    futureEventsPromise = fetchEvents("future");
    pastEventsPromise = fetchEvents("past");
    return data["data"][0];
  }

  function fetchTrack() {
    wavesurfer = WaveSurfer.create({
      container: "#waveform",
      backend: "MediaElement",
      waveColor: "white",
      progressColor: "deepskyblue",
      height: "48",
      cursorColor: "white",
      barWidth: "1",
      barHeight: "2",
    });
    wavesurfer.load(api._transport._url + "/assets/" + trackID);
  }

  $: isPlaying = false;
  $: playIcon = isPlaying == false ? "play_arrow" : "pause";

  function togglePlay(isP) {
    if (isP != true) {
      wavesurfer.play();
      isPlaying = true;
    } else {
      wavesurfer.pause();
      isPlaying = false;
    }
  }

  $: imageFiles = [];

  function uploadImage() {
    const formData = new FormData();
    formData.append("file", imageFiles[0]);
    api.transport.axios.post("/files?access_token=" + api._storage.auth_token, formData).then((image) => {
      api
        .items("performers")
        .updateOne(performerID, {
          image: image.data.data.id,
        })
        .then((result) => {
          location.reload();
        })
        .catch((error) => {});
    });
    return;
  }

  $: trackFiles = [];

  function uploadTrack() {
    const formData = new FormData();
    formData.append("file", trackFiles[0]);
    api.transport.axios.post("/files?access_token=" + api._storage.auth_token, formData).then((track) => {
      api
        .items("performers")
        .updateOne(performerID, {
          track: track.data.data.id,
        })
        .then((result) => {
          location.reload();
        })
        .catch((error) => {});
    });
    return;
  }

  function publishPerformer() {
    api
      .items("performers")
      .updateOne(performerID, {
        status: "published",
      })
      .then((result) => {
        location.reload();
      })
      .catch((error) => {});
  }

  async function fetchPlays(performerID) {
    // Get the number of plays
    const data = await api.items("performers_played").readMany({
      filter: {
        performer: {
          _eq: performerID,
        },
      },
      limit: 1,
      meta: "*",
      fields: ["id"],
    });
    playsCount = data.meta.filter_count.toLocaleString();
  }

  async function fetchLikes(performerID) {
    // Get the number of likes
    const data1 = await api.items("performers_liked").readMany({
      filter: {
        performer: {
          _eq: performerID,
        },
      },
      limit: 1,
      meta: "*",
      fields: ["id"],
    });
    likesCount = data1.meta.filter_count.toLocaleString();

    // Check if the user has alreadManyy liked the performer
    if ($user) {
      const data2 = await api.items("performers_liked").readMany({
        filter: {
          performer: {
            _eq: performerID,
          },
          user_created: {
            _eq: $user.id,
          },
        },
        limit: 1,
        fields: ["id"],
      });
      hasLiked = data2["data"].length ? true : false;
      likeID = data2["data"].length ? data2["data"][0].id : null;
    }
  }

  async function fetchShares(performerID) {
    // Get the number of shares
    const data = await api.items("performers_shared").readMany({
      filter: {
        performer: {
          _eq: performerID,
        },
      },
      limit: 1,
      meta: "*",
      fields: ["id"],
    });
    sharesCount = data.meta.filter_count.toLocaleString();
  }

  async function fetchEvents(eventType) {
    let dateFilter = getEventsDateFilter(eventType);
    let sort = "startdate";
    let dateOptions = {
      month: "short",
      day: "numeric",
    };

    if (eventType == "past") {
      sort = "-startdate";
      dateOptions.year = "numeric";
    }

    // Fetch from the API
    const data = await api.items("events").readMany({
      filter: {
        _and: [
          {
            performers: {
              performers_id: {
                id: {
                  _eq: performerID,
                },
              },
            },
          },
          { startdate: dateFilter },
        ],
      },
      sort: sort,
      fields: ["*", "venue.*", "performers.*"],
    });
    data["data"].forEach((event, i) => {
      let date = new Date(event.startdate);
      data["data"][i]["startDate0"] = date.toISOString();
      data["data"][i]["startDate1"] = new Intl.DateTimeFormat("en-US", dateOptions).format(date);
      data["data"][i]["startDate2"] = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
      }).format(date);

      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      minutes = minutes == "00" ? "" : ":" + minutes;
      data["data"][i]["startDate3"] = hours + minutes + ampm;
    });
    return data["data"];
  }

  async function like(performerID) {
    // Only let logged in users to like
    if ($user) {
      // the user has not liked the performer yet
      if (!hasLiked) {
        // create a new item in the performers_liked collection
        api
          .items("performers_liked")
          .createOne({
            performer: performerID,
          })
          .then((result) => {
            fetchLikes(performerID);
          })
          .catch((error) => {
            showDialog = true;
            dialogError.message = error.message;
          });
      } else {
        // delete the item in the performers_liked collection
        api
          .items("performers_liked")
          .deleteOne(likeID)
          .then((result) => {
            fetchLikes(performerID);
          })
          .catch((error) => {
            showDialog = true;
            dialogError.message = error.message;
          });
      }
    } else {
      $goto("/user/login?destination=" + window.location.pathname);
    }
  }

  async function share(performerID) {
    // Only let logged in users to share
    if ($user) {
      // If share functionality is supported by the browser (mobile)
      if (navigator.share) {
        navigator
          .share({
            title,
            shareURL,
          })
          .then(() => {
            logShare(performerID);
          })
          .catch((err) => {
            console.log("Couldn't share");
          });
      } else {
        // Share functionality is not supported (desktop)
        showDialogShare = true;
      }
    } else {
      $goto("/user/login?destination=" + window.location.pathname);
    }
  }

  function copyShare(performerID) {
    document.getElementById("performer-actions-share-url").removeAttribute("disabled");
    document.getElementById("performer-actions-share-url").select();
    document.execCommand("copy");
    document.getElementById("performer-actions-share-url").setAttribute("disabled", "disabled");
    logShare(performerID);
    showSnackbar = true;
  }

  async function logView(performerID) {
    api
      .items("performers_viewed")
      .createOne({
        performer: performerID,
      })
      .then((result) => {})
      .catch((error) => {
        // showDialog = true;
        // dialogError.message = error.message;
      });
  }

  async function logShare(performerID) {
    showDialogShare = false;
    api
      .items("performers_shared")
      .createOne({
        performer: performerID,
      })
      .then((result) => {
        fetchShares(performerID);
      })
      .catch((error) => {
        // showDialog = true;
        // dialogError.message = error.message;
      });
  }

  async function logPlay(performerID, trackID, isPlaying) {
    // Only if starting to play
    if (!isPlaying && wavesurfer.getCurrentTime() == 0) {
      api
        .items("performers_played")
        .createOne({
          performer: performerID,
          track: trackID,
        })
        .then((result) => {
          fetchPlays(performerID);
        })
        .catch((error) => {
          // showDialog = true;
          // dialogError.message = error.message;
        });
    }
  }
</script>

<div class="md:flex mb-12" itemscope itemtype="https://schema.org/MusicGroup">
  <div class="-mt-16 md:flex-none md:w-96 md:mt-0 md:ml-3 md:mr-3">
    <div class="container">
      <div class="relative">
        <div class="absolute bottom-0 w-full mb-12">
          <div id="waveform" class="relative z-10 opacity-75 w-auto ml-12 mr-3 mb-4" />
        </div>

        {#await performerPromise then performerObject}
          <meta itemprop="name" content={performerObject.name} />
          <meta itemprop="url" content="/performers/{performerObject.slug}" />
          <div class="relative">
            {#if performerObject.status == "published" && $user != undefined && performerObject.user_created != null && performerObject.user_created == $user.id}
              <div class="fixed z-20 bottom-0 right-0 md:top-0 md:mr-0 md:absolute lg:absolute lg:mr-1">
                <Button icon="add" href={"/events/add?performer=" + performerID} class="pt-3 pr-3 pb-3 pl-3 mr-3 mb-3 md:mt-16 lg:mr-2" />
              </div>
            {/if}
            {#if performerObject.image}
              <img src={api._transport._url + "/assets/" + performerObject.image + "?key=medium"} alt={title} width="425" height="425" style="filter: grayscale(100%)" />
            {:else if performerObject.status == "published"}
              <div class="w-screen md:w-full h-96 -mt-4 relative">
                <div class="-mt-4 -ml-4 pt-32">
                  <div class="h-40 w-40 ml-32 rounded-full relative">
                    <span class="material-icons align-middle opacity-50" style="font-size:10rem"> headset </span>
                  </div>
                </div>
              </div>
            {:else if performerObject.status != "published" && $user != undefined && performerObject.user_created == $user.id}
              <div class="bg-gray-800 w-screen md:w-full h-96 -mt-4 relative">
                <div class="-mt-4 -ml-4 pt-32">
                  <div class="h-40 w-40 ml-32 rounded-full relative">
                    <Button class="absolute rounded-full cursor-pointer h-full w-full">Upload Image</Button>
                    <input
                      type="file"
                      accept="image/*"
                      bind:files={imageFiles}
                      on:change={uploadImage}
                      class="absolute z-20 opacity-0 rounded-full top-0 left-0 w-full h-full m-0 p-0 cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            {/if}

            <div class="absolute right-0 bottom-0 mb-16 mr-2 px-1 md:mt-8 text-right">
              <ul>
                {#if performerObject.genres}
                  {#each performerObject.genres as genre}
                    {#if genres[genre] != undefined}
                      <li class="leading-tight">
                        <span itemprop="genre" class="bg-black bg-opacity-75 rounded-sm px-1 mb-1 text-sm">{genres[genre]}</span>
                      </li>
                    {/if}
                  {/each}
                {/if}

                {#if performerObject.addresslocality && performerObject.addresscountry}
                  <li class="leading-tight">
                    <span itemprop="address" itemscope itemtype="https://schema.org/PostalAddress" class="bg-black bg-opacity-75 rounded-sm px-1 text-sm">
                      <span itemprop="addressLocality">{performerObject.addresslocality}</span>,
                      <span itemprop="addressCountry">{performerObject.addresscountry}</span>
                    </span>
                  </li>
                {/if}
              </ul>
            </div>

            {#if performerObject.track}
              <div class="hidden">{fetchTrack()}</div>
              <div class="absolute bottom-0 px-3">
                <Button small icon={playIcon} on:click={togglePlay(isPlaying)} on:click={logPlay(performerID, performerObject.track, isPlaying)} class="opacity-75" />
                <span class="block w-6 text-center mt-1 ml-1 text-xs font-bold text-primary-600">{playsCount}</span>
              </div>
            {:else if $user != undefined && performerObject.user_created == $user.id}
              <div class="relative -mt-16 mb-12 text-center">
                <input type="file" accept=".mp3" bind:files={trackFiles} on:change={uploadTrack} class="absolute z-10 opacity-0 top-0 left-0 m-0 p-0 h-10 w-full cursor-pointer" />
              </div>
            {/if}
            {#if performerObject.status == "draft"}
              <div class="absolute top-0 mt-16 ml-16">
                <div class="bg-black bg-opacity-75 rounded-sm px-2 py-1 mb-3 text-xs text-error-500">NOT PUBLISHED</div>
              </div>
            {/if}
          </div>

          {#if performerObject.status == "draft"}
            <div class="h-10 mt-2">&nbsp;</div>
          {/if}

          {#if performerObject.status == "published"}
            <div class="flex text-center align-middle mt-2">
              <div class="flex-1 bg-black z-10">
                {#if hasLiked}
                  <Button on:click={like(performerID)} text add="py-2 w-full">
                    <span class="material-icons align-middle"> favorite </span>
                    <span class="align-middle ml-2 text-xs font-bold">{likesCount}</span>
                  </Button>
                {:else}
                  <Button on:click={like(performerID)} text color="white" add="py-2 w-full">
                    <span class="material-icons align-middle"> favorite </span>
                    <span class="align-middle ml-2 text-xs font-bold">{likesCount}</span>
                  </Button>
                {/if}
              </div>
              <div class="flex-1 bg-black z-10">
                <Button text color="white" on:click={share(performerID)} add="py-2 w-full">
                  <span class="material-icons flipX align-middle"> reply </span>
                  <span class="align-middle ml-2 text-xs font-bold">{sharesCount}</span>
                </Button>
              </div>
              {#if performerObject.website}
                <div class="flex-1">
                  <Button text color="white" add="py-2 w-full" href={performerObject.website}>
                    <span class="material-icons align-middle"> public </span>
                  </Button>
                </div>
              {/if}

              {#if $user != undefined && performerObject.user_created != null && performerObject.user_created == $user.id}
                <div class="flex-1 bg-black z-10">
                  <Button text on:click={() => (showDialog = true)} color="white" add="py-2 w-full">
                    <span class="material-icons align-middle"> edit </span>
                  </Button>
                </div>
              {/if}
            </div>
          {/if}
        {:catch error}
          <div class="e404">
            {#if error.message == "data.data[0] is undefined"}
              <div class="mb-24 text-error-500">Page not found</div>
              {#if !$user}
                <Button href="/user/login?destination={window.location.pathname}" block outlined>Log in</Button>
              {:else}
                <div>
                  <Button href="/" block outlined>HOME</Button>
                </div>
              {/if}
            {:else}
              <p class="text-error-500">{error.message}</p>
            {/if}
          </div>
        {/await}
      </div>
    </div>
  </div>

  {#await performerPromise then performerObject}
    {#if performerObject.status == "draft" && $user != undefined && performerObject.user_created == $user.id}
      <div class="container w-64 mx-auto">
        <div>
          {#if performerObject.image}
            <div class="relative text-center">
              <Button block color="success" add="rounded-full mb-4">
                <span class="material-icons mr-2 text-xs">done</span>
                Update Image
              </Button>
              <input type="file" accept="image/*" bind:files={imageFiles} on:change={uploadImage} class="absolute z-10 opacity-0 top-0 left-0 m-0 p-0 h-10 w-full cursor-pointer" />
            </div>
          {/if}

          {#if !performerObject.image}
            <Button block disabled add="rounded-full mb-4">Add Genres</Button>
          {:else if !performerObject.genres}
            <Button href={"/performers/" + performerObject.slug + "/update-genre"} block add="rounded-full mb-4">Add Genres</Button>
          {:else}
            <Button href={"/performers/" + performerObject.slug + "/update-genre"} block color="success" add="rounded-full mb-4">
              <span class="material-icons mr-2 text-xs">done</span>
              Update Genres
            </Button>
          {/if}

          <div class="relative text-center">
            {#if !performerObject.image || !performerObject.genres}
              <Button block disabled add="rounded-full mb-4">Upload Track</Button>
            {:else if !performerObject.track}
              <Button block add="rounded-full mb-4">Upload Track</Button>
              <input type="file" accept=".mp3" bind:files={trackFiles} on:change={uploadTrack} class="absolute z-10 opacity-0 top-0 left-0 m-0 p-0 h-10 w-full cursor-pointer" />
            {:else}
              <Button color="success" block add="rounded-full mb-4">
                <span class="material-icons mr-2 text-xs">done</span>
                Update Track
              </Button>
              <input type="file" accept=".mp3" bind:files={trackFiles} on:change={uploadTrack} class="absolute z-10 opacity-0 top-0 left-0 m-0 p-0 h-10 w-full cursor-pointer" />
            {/if}
          </div>

          {#if !performerObject.image || !performerObject.genres || !performerObject.track}
            <Button block disabled add="rounded-full">Publish</Button>
          {:else}
            <Button on:click={publishPerformer} block add="rounded-full">Publish</Button>
          {/if}
        </div>
      </div>
    {/if}
    {#if performerObject.status == "published"}
      <div class="px-3 md:-mt-3 md:w-96">
        {#await tonightEventsPromise then tonightEventsObject}
          {#if tonightEventsObject && tonightEventsObject.length != 0}
            <h4 class="uppercase font-bold">Tonight</h4>
            <ul class="mb-6">
              {#each tonightEventsObject as event}
                <li itemscope itemtype="https://schema.org/MusicEvent" class="relative mb-3 rounded-lg">
                  <meta itemprop="performer" content={title} />
                  {#if $user != undefined && event.user_created == $user.id}
                    <div class="absolute top-0 right-0">
                      <Button href="/events/{event.slug}/update" color="white" class="absolute top right bg-black hover:bg-black mt-3 mr-3 md:mt-3 pt-2 pr-2 pb-2 pl-2" icon="edit" />
                    </div>
                  {/if}
                  <span class="block absolute z-10 top-0 mt-2 ml-2 px-2 py-1 font-bold bg-black bg-opacity-50 rounded">
                    {event.venue.name} / {event.startDate3}
                    <meta itemprop="startDate" content={event.startDate0} />
                  </span>
                  <a itemprop="location" itemscope itemtype="https://schema.org/LocalBusiness" href="/venues/{event.venue.slug}" class="block relative">
                    <meta itemprop="name" content={event.venue.name} />
                    {#if event.ticketurl}
                      <img src={getMapboxImageURL(event.venue)} alt="Map" class="border border-white border-opacity-25 rounded-t-lg opacity-75" />
                    {:else}
                      <img src={getMapboxImageURL(event.venue)} alt="Map" class="border border-white border-opacity-25 rounded-lg opacity-75" />
                    {/if}

                    {#if event.venue.requiredminage != undefined || event.venue.areaserved != undefined || event.venue.addresslocality != undefined}
                      <span class="block absolute left-0 bottom-0 mb-4 ml-2 text-xs">
                        {#if event.venue.requiredminage != undefined}
                          <span class="px-2 py-1 bg-black bg-opacity-50 float-left clear-both mb-1">
                            <span class="material-icons text-xs mr-2">pan_tool</span>
                            {event.venue.requiredminage}+
                          </span>
                        {/if}
                        {#if event.venue.areaserved != undefined}
                          <span class="px-2 py-1 bg-black bg-opacity-50 float-left clear-both mb-1 rounded">
                            <span class="material-icons text-xs mr-2">location_searching</span>
                            <span itemprop="areaServed">{event.venue.areaserved}</span>
                          </span>
                        {/if}

                        {#if event.venue.addresslocality != undefined && event.venue.addressregion != undefined}
                          <span class="px-2 py-1 bg-black bg-opacity-50 float-left clear-both rounded">
                            <span class="material-icons text-xs mr-2">explore</span>
                            <span itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
                              <span itemprop="addressLocality">{event.venue.addresslocality}</span>,
                              <span itemprop="addressRegion">{event.venue.addressregion}</span>,
                              <span itemprop="addressCountry">{event.venue.addresscountry}</span>
                            </span>
                          </span>
                        {/if}
                      </span>
                    {/if}

                    <span class="block absolute right-0 bottom-0 mb-2 mr-2 px-1 md:mt-8 text-right">
                      <Button icon="directions" href={getDirectionsURL(event.venue)} class="z-20 bg-black bg-opacity-50 pt-2 pr-2 pb-2 pl-2 mr-0 mb-3 md:mt-16 lg:mr-0" remove="bg-primary-500" />
                    </span>
                  </a>
                  {#if event.ticketurl}
                    <a itemprop="offers" class="block py-3 rounded-b-lg bg-primary-600 text-center uppercase font-bold" href={event.ticketurl}>Tickets</a>
                  {/if}
                </li>
              {/each}
            </ul>
          {/if}
        {:catch error}
          <div class="e404">
            <p class="text-error-500">{error.message}</p>
          </div>
        {/await}

        {#await performerPromise then performerObject}
          {#if residency_exists}
            <h4 class="uppercase font-bold">Residency</h4>
            <ul class="mb-6">
              {#each days as day}
                {#if performerObject["residency_day_" + day.num] != undefined}
                  <li itemscope itemtype="https://schema.org/MusicEvent" class="rounded-lg border border-gray-900 border-opacity-100 bg-gray-900 bg-opacity-50 mb-3">
                    <meta itemprop="performer" content={title} />
                    <a itemprop="location" itemscope itemtype="https://schema.org/LocalBusiness" href="/venues/{performerObject['residency_day_' + day.num].slug}" class="block py-3">
                      <span class="inline-block w-1/3 text-right border-r border-gray-900 pr-3">
                        <!-- Schema.org for Schedule is pending implementation -->
                        <!--span itemprop="eventSchedule" itemscope itemtype="https://schema.org/Schedule">
                        <span itemprop="byDay" class="block font-bold"
                          >{day.label}</span
                        >
                      </span-->
                        <span class="block font-bold">{day.label}</span>
                        <span class="block">&nbsp;</span>
                      </span>
                      <span class="inline-block pl-2 align-top">
                        <span class="block font-bold">
                          {performerObject["residency_day_" + day.num].name.substring(0, 28)}
                          <meta itemprop="name" content={performerObject["residency_day_" + day.num].name} />
                        </span>
                        <span itemprop="address" itemscope itemtype="https://schema.org/PostalAddress" class="block text-xs font-bold">
                          <span itemprop="addressLocality">{performerObject["residency_day_" + day.num].addresslocality}</span>,
                          <span itemprop="addressRegion">{performerObject["residency_day_" + day.num].addressregion}</span>,
                          <span itemprop="addressCountry">{performerObject["residency_day_" + day.num].addresscountry.toUpperCase()} </span>
                        </span>
                      </span>
                    </a>
                  </li>
                {/if}
              {/each}
            </ul>
          {/if}
        {/await}

        {#await futureEventsPromise then futureEventsObject}
          {#if futureEventsObject}
            <h4 class="uppercase font-bold">Upcoming Events</h4>
            <ul class="mb-6">
              {#each futureEventsObject as event}
                <li itemscope itemtype="https://schema.org/MusicEvent" class="relative rounded-lg border border-gray-900 border-opacity-100 bg-gray-900 bg-opacity-50 mb-3">
                  <meta itemprop="performer" content={title} />
                  <meta itemprop="startDate" content={event.startDate0} />
                  {#if $user != undefined && event.user_created == $user.id}
                    <div class="absolute top-0 right-0">
                      <Button href="/events/{event.slug}/update" color="white" class="absolute top right bg-black hover:bg-black mt-3 mr-3 md:mt-3 pt-2 pr-2 pb-2 pl-2" icon="edit" />
                    </div>
                  {/if}
                  <a itemprop="location" itemscope itemtype="https://schema.org/LocalBusiness" href="/venues/{event.venue.slug}" class="block container py-3">
                    <span class="inline-block w-1/3 text-right border-r border-gray-900 pr-3">
                      <span class="block font-bold">{event.startDate1}</span>
                      <span class="block text-xs font-bold">{event.startDate2 + " " + event.startDate3}</span>
                    </span>
                    <span class="inline-block pl-2 align-top">
                      <span class="block font-bold">
                        {event.venue.name.substring(0, 28)}
                        <meta itemprop="name" content={event.venue.name} />
                        <meta itemprop="areaServed" content={event.venue.areaserved} />
                      </span>
                      <span itemprop="address" itemscope itemtype="https://schema.org/PostalAddress" class="block text-xs font-bold">
                        <span itemprop="addressLocality">{event.venue.addresslocality}</span>,
                        <span itemprop="addressRegion">{event.venue.addressregion}</span>,
                        <span itemprop="addressCountry">{event.venue.addresscountry.toUpperCase()}</span>
                      </span>
                    </span>
                  </a>
                  {#if event.ticketurl}
                    <a itemprop="offers" class="block py-2 rounded-b-lg bg-primary-600 text-center text-xs uppercase font-bold" href={event.ticketurl}>Tickets</a>
                  {/if}
                </li>
              {:else}
                <div class="text-gray-600">No upcoming events found.</div>
              {/each}
            </ul>
          {/if}
        {:catch error}
          <div class="e404">
            <p class="text-error-500">{error.message}</p>
          </div>
        {/await}

        {#await pastEventsPromise then pastEventsObject}
          {#if pastEventsObject}
            <h4 class="uppercase font-bold">Past Events</h4>
            <ul class="mb-6">
              {#each pastEventsObject as event}
                <li itemscope itemtype="https://schema.org/MusicEvent" class="relative rounded-lg border border-gray-900 border-opacity-100 bg-gray-900 bg-opacity-50 mb-3">
                  <meta itemprop="performer" content={title} />
                  <meta itemprop="startDate" content={event.startDate0} />
                  {#if $user != undefined && event.user_created == $user.id}
                    <div class="absolute top-0 right-0">
                      <Button href="/events/{event.slug}/update" color="white" class="absolute top right bg-black hover:bg-black mt-3 mr-3 md:mt-3 pt-2 pr-2 pb-2 pl-2" icon="edit" />
                    </div>
                  {/if}
                  <a itemprop="location" itemscope itemtype="https://schema.org/LocalBusiness" href="/venues/{event.venue.slug}" class="block container py-3">
                    <span class="inline-block w-1/3 text-right border-r border-gray-900 pr-3">
                      <span class="block font-bold whitespace-no-wrap">{event.startDate1}</span>
                      <span class="block text-xs font-bold">{event.startDate2 + " " + event.startDate3}</span>
                    </span>
                    <span class="inline-block pl-2 align-top">
                      <span class="block font-bold">
                        {event.venue.name.substring(0, 28)}
                        <meta itemprop="name" content={event.venue.name} />
                        <meta itemprop="areaServed" content={event.venue.areaserved} />
                      </span>
                      <span itemprop="address" itemscope itemtype="https://schema.org/PostalAddress" class="block text-xs font-bold">
                        <span itemprop="addressLocality">{event.venue.addresslocality}</span>,
                        <span itemprop="addressRegion">{event.venue.addressregion}</span>,
                        <span itemprop="addressCountry">{event.venue.addresscountry.toUpperCase()}</span>
                      </span>
                    </span>
                  </a>
                </li>
              {:else}
                <div class="text-gray-600">No past events found.</div>
              {/each}
            </ul>
          {/if}
        {:catch error}
          <div class="e404">
            <p class="text-error-500">{error.message}</p>
          </div>
        {/await}
      </div>
    {/if}
  {/await}
</div>

<Dialog bind:value={showDialog} navigation="true" opacity="0.85" class="ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4">
  <div slot="title" class="pl-4 font-normal">Update performer profile</div>
  <List items={menuList} />
  <div slot="actions">
    <Button text on:click={() => (showDialog = false)} color="white" class="hover:bg-gray-transDark" icon="cancel" iconClass="mr-3">Cancel</Button>
  </div>
</Dialog>

<!-- Share Performer Dialog -->
<Dialog bind:value={showDialogShare} opacity="0.85" class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4">
  <h5 slot="title">Copy to clipboard</h5>
  <TextField disabled textarea outlined value={shareURL} rows="2" id="performer-actions-share-url" class="text-white text-xs font-bold bg-gray-900" />
  <div slot="actions">
    <Button on:click={() => copyShare(performerID)}>Copy</Button>
    <Button on:click={() => (showDialogShare = false)} text color="white">Close</Button>
  </div>
</Dialog>

<Snackbar bind:value={showSnackbar}>
  <div>Link has been copied to clipboard</div>
</Snackbar>

<style>
  .e404 {
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .flipX {
    transform: scaleX(-1);
  }
</style>
