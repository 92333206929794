<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button, Dialog, Select, TextField } from "smelte";
  import { api } from "../../lib/api";
  import { user } from "../user/auth.js";
  import slug from "slug";

  // Redirect to the performer profile if the user already has one
  if ($user.performer) {
    $goto("/performers/" + $user.performer);
  }

  // Set page and nav titles
  $: metatags.title = "Register as a performer • NightLife.FM";
  navTitle.set("NightLife.FM");

  let countriesPromise = fetchCountries();
  async function fetchCountries() {
    const data = await api.transport.axios.get(
      "/fields/performers/addresscountry"
    );
    return [{ value: "US", text: "United States" }].concat(
      data.data.data.meta.options.choices
    );
  }

  let formState = {
    name: "",
    addresslocality: "",
    addresscountry: [],
  };

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  function formSubmit(event) {
    let performerSlug = slug(
      formState.name +
        "-" +
        formState.addresslocality +
        "-" +
        formState.addresscountry
    );
    api
      .items("performers")
      .createOne({
        name: formState.name,
        addresslocality: formState.addresslocality,
        addresscountry: formState.addresscountry,
        status: "draft",
        slug: performerSlug,
      })
      .then((result1) => {
        // Add performer field to the user account and reload
        api.users.me
          .update({ performer: performerSlug })
          .then((result) => {
            // update user data in the storage
            user.set(result);
            dialogError.message = "Changes saved";
            messageType = "";
            $goto("/performers/" + performerSlug);
          })
          .catch((error) => {
            showDialog = true;
            dialogError.message = error.message;
          });
      })
      .catch((error) => {
        showDialog = true;
        dialogError.message = error.message;
      });
  }
</script>

<div class="form container m-auto md:max-w-sm">
  <form on:submit|preventDefault class="container m-auto mt-12 md:max-w-sm">
    <div class="text-lg ml-6 mb-6">Register as a performer</div>
    <div class="px-6">
      <TextField
        label="Stage name"
        required="true"
        outlined
        prepend="account_circle"
        bind:value={formState.name}
      />

      <TextField
        label="City"
        required="true"
        outlined
        prepend="location_city"
        bind:value={formState.addresslocality}
      />

      {#await countriesPromise then countries}
        <Select
          bind:value={formState.addresscountry}
          label="Country"
          required="true"
          outlined
          items={countries}
          listClasses="z-50 fixed top-0 left-0 w-full h-full bg-black"
        />
      {/await}
    </div>
    <div class="px-6 mt-16">
      <Button on:click={formSubmit} class="rounded-full">Save</Button>
      <Button text href="/">Cancel</Button>
    </div>
  </form>
</div>

<Dialog
  bind:value={showDialog}
  opacity="0.85"
  class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
>
  <div class="{messageType} text-center">{dialogError.message}</div>
  <div slot="actions">
    <Button text color="white" on:click={() => (showDialog = false)}>OK</Button>
  </div>
</Dialog>

<style>
  .form {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
</style>
