<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button, Dialog, List, TextField } from "smelte";
  import { api } from "../../lib/api";
  import { user } from "./auth.js";

  // Redirect to homepage if already logged in
  if ($user) {
    $goto("/");
  }

  // Set page and nav titles
  $: metatags.title = "Log in • NightLife.FM";
  navTitle.set("NightLife.FM");

  let loginFormState = {
    email: "",
    password: "",
  };

  let showDialog = false;
  let dialogError = "";
  let gotoDest;

  function loginFormSubmit() {
    api.auth
      .login({
        email: loginFormState.email.trim(),
        password: loginFormState.password,
      })
      .then((result) => {
        api.users.me.read().then((result1) => {
          user.set(result1);
        });
        let url = new URL(window.location.href);
        if (url.searchParams.get("destination") !== undefined) {
          gotoDest = url.searchParams.get("destination");
        }
        $goto(gotoDest ? gotoDest : "/");
      })
      .catch((error) => {
        showDialog = true;
        dialogError = error;
      });
  }
</script>

<form on:submit|preventDefault class="container m-auto mt-12 md:max-w-sm ">
  <div class="text-lg ml-6 mb-6">Log in</div>
  <div class="px-6">
    <TextField
      type="email"
      label="Email"
      outlined
      prepend="account_circle"
      bind:value={loginFormState.email}
    />
    <TextField
      label="Password"
      type="password"
      outlined
      prepend="lock"
      bind:value={loginFormState.password}
    />
  </div>
  <div class="px-6">
    <Button on:click={loginFormSubmit} block class="rounded-full py-3"
      >Log in</Button
    >
    <List
      items={[
        { text: "Forgot password?", to: "/user/request-password" },
        { text: "Sign up", to: "/user/signup" },
      ]}
      dense
      classes="mt-16 -ml-3"
    />
  </div>
</form>

<Dialog
  bind:value={showDialog}
  opacity="0.85"
  class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
>
  <div class="text-error-500 text-center">{dialogError.message}</div>
  <div slot="actions">
    <Button text color="white" on:click={() => (showDialog = false)}>OK</Button>
  </div>
</Dialog>
