<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button } from "smelte";
  import { user } from "./auth.js";
  import { api } from "../../lib/api";

  // Set page and nav titles
  $: metatags.title = "Log out  • NightLife.FM";
  navTitle.set("NightLife.FM");

  function logout() {
    user.set(null);
    api.auth.logout();
    $goto("/");
  }
</script>

<style>
  .logout {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
</style>

<div class="logout container items-center m-auto md:max-w-sm">
  <div class="m-6 text-center text-lg">Are you sure you want to log out?</div>
  <div class="px-12 text-center">
    <Button on:click={logout} class="rounded-full">Log out</Button>
    <Button text href="/">Cancel</Button>
  </div>
</div>
