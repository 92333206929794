<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../../lib/store.js";
  import { Button, Dialog } from "smelte";
  import { api } from "../../../lib/api";
  import { user } from "../../user/auth.js";

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  export let performer;

  let title = "NightLife.FM";
  let performerID = null;
  let performerPromise = fetchPerformer();

  // Set page and nav titles
  $: metatags.title = title;
  navTitle.set(title);

  async function fetchPerformer() {
    const data = await api.items("performers").readMany({
      filter: {
        slug: {
          _eq: performer,
        },
      },
    });
    title = data["data"][0].name;
    navTitle.set(title);
    performerID = data["data"][0].id;
    return data["data"][0];
  }

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  $: trackFiles = [];

  function uploadTrack() {
    const formData = new FormData();
    formData.append("file", trackFiles[0]);
    api.transport.axios
      .post("/files?access_token=" + api._storage.auth_token, formData)
      .then((track) => {
        api
          .items("performers")
          .updateOne(performerID, {
            track: track.data.data.id,
          })
          .then((result) => {
            window.history.back();
          })
          .catch((error) => {});
      });
    return;
  }

  function cancelTrack() {
    window.history.back();
  }
</script>

{#await performerPromise then performerObject}
  {#if performerObject.user_created == $user.id}
    <div class="mx-3">
      <div class="text-lg mt-12">Update sample track</div>
      <div class="relative mt-24 text-center">
        <Button class="rounded-full">Choose new track</Button>
        <input
          type="file"
          accept=".mp3"
          bind:files={trackFiles}
          on:change={uploadTrack}
          class="absolute z-10 opacity-0 top-0 left-0 m-0 p-0 h-10 w-full cursor-pointer"
        />
        <Button text on:click={cancelTrack} class="z-20">Cancel</Button>
      </div>
    </div>

    <Dialog
      bind:value={showDialog}
      opacity="0.85"
      class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
    >
      <div class="{messageType} text-center">{dialogError.message}</div>
      <div slot="actions">
        <Button text href="/user/account" color="white">OK</Button>
      </div>
    </Dialog>
  {/if}
{:catch error}
  <div class="e404">
    {#if error.message == "data.data[0] is undefined"}
      <div class="mb-24 text-error-500">Page not found</div>
      {#if !$user}
        <Button
          href="/user/login?destination={window.location.pathname}"
          block
          outlined
        >
          Log in
        </Button>
      {:else}
        <div>
          <Button href="/" block outlined>HOME</Button>
        </div>
      {/if}
    {:else}
      <p class="text-error-500">{error.message}</p>
    {/if}
  </div>
{/await}
