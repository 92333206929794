<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button, Dialog, List, TextField } from "smelte";
  import { api } from "../../lib/api";
  import { user } from "./auth.js";

  // Redirect to homepage if alreadManyy logged in
  if ($user) {
    $goto("/");
  }

  // Set page and nav titles
  $: metatags.title = "Reset password • NightLife.FM";
  navTitle.set("NightLife.FM");

  let resetPasswordFormState = {
    newPassword: "",
    retypePassword: "",
  };

  let passwordsDontMatch = false;
  let submitDisabled = true;

  let showDialog = false;
  let isError = true;
  let dialogText = "";
  let messageClass = "text-error-500";

  function checkPass() {
    if (
      resetPasswordFormState.newPassword !=
      resetPasswordFormState.retypePassword
    ) {
      passwordsDontMatch = "Entered passwords do not match";
      submitDisabled = true;
    } else {
      passwordsDontMatch = false;
      if (resetPasswordFormState.newPassword.length >= 8) {
        submitDisabled = false;
      }
    }
  }

  function resetPasswordFormSubmit(event) {
    let urlParams = new URLSearchParams(window.location.search);
    if (
      resetPasswordFormState.newPassword ==
      resetPasswordFormState.retypePassword
    ) {
      api.transport.axios
        .post("/auth/password/reset", {
          password: resetPasswordFormState.newPassword,
          token: urlParams.get("token"),
        })
        .then(function (result) {
          isError = false;
          showDialog = true;
          dialogText = "Your password has been reset";
          messageClass = "";
        })
        .catch((error) => {
          showDialog = true;
          dialogText = error.message;
          if (dialogText.startsWith("Expired Reset Password token")) {
            dialogText =
              "Please make a new password request as this request has expired";
          }
        });
    }
  }
</script>

<div class="reset-password container m-auto md:max-w-sm">
  <form on:submit|preventDefault class="container m-auto mt-12 md:max-w-sm ">
    <div class="text-lg ml-6 mb-6">Reset password</div>
    <div class="px-6">
      <TextField
        label="New password"
        type="password"
        minlength="8"
        outlined
        prepend="lock"
        hint="Minimum 8 characters"
        persistentHint="true"
        on:input={checkPass}
        bind:value={resetPasswordFormState.newPassword}
      />
      <div class="my-12" />
      <TextField
        label="Retype new password"
        type="password"
        minlength="8"
        outlined
        prepend="lock"
        error={passwordsDontMatch}
        on:input={checkPass}
        bind:value={resetPasswordFormState.retypePassword}
      />
    </div>
    <div class="px-6 mt-12">
      <Button
        on:click={resetPasswordFormSubmit}
        disabled={submitDisabled}
        class="rounded-full"
      >
        Reset password
      </Button>
      <Button text href="/">Cancel</Button>
    </div>
  </form>
</div>

<Dialog
  bind:value={showDialog}
  opacity="0.85"
  class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
>
  <div class="{messageClass} text-center">{dialogText}</div>
  <div slot="actions" class="px-6 mt-12">
    {#if isError}
      <Button href="/user/request-password" class="rounded-full">
        Request new password
      </Button>
      <Button text color="white" on:click={() => (showDialog = false)}>
        Cancel
      </Button>
    {:else}
      <Button href="/user/login" class="rounded-full">Log in</Button>
    {/if}
  </div>
</Dialog>

<style>
  .reset-password {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
</style>
