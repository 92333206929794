import {
  writable
} from "svelte/store";

export const venuesFilter = writable(
  localStorage.getItem("venuesFilter") ?
  JSON.parse(localStorage.getItem("venuesFilter")) :
  {
    metro: null,
    date: null,
    neighborhoods: ["Any Neighborhood"],
    lat: null,
    lon: null,
    updated: new Date()
  }
);

venuesFilter.subscribe((value) => {
  if (value) localStorage.setItem("venuesFilter", JSON.stringify(value));
});
