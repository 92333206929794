import {
  writable
} from 'svelte/store';
export const navTitle = writable('NightLife.FM');
export const days = [{
    num: 0,
    label: "Sundays"
  },
  {
    num: 1,
    label: "Mondays"
  },
  {
    num: 2,
    label: "Tuesdays"
  },
  {
    num: 3,
    label: "Wednesdays"
  },
  {
    num: 4,
    label: "Thursdays"
  },
  {
    num: 5,
    label: "Fridays"
  },
  {
    num: 6,
    label: "Saturdays"
  },
];
export const genres = {
  alternative: "Alternative",
  classical: "Classical",
  country: "Country",
  disco: "Disco",
  electronic: "Electronic",
  folk: "Folk",
  hiphop: "Hip Hop",
  indie: "Indie",
  jazzblues: "Jazz & Blues",
  latin: "Latin",
  metal: "Metal",
  piano: "Piano",
  pop: "Pop",
  rbsoul: "R&B & Soul",
  reggae: "Reggae",
  rock: "Rock",
  world: "World",
  other: "Other",
};

export const genreGroups = [{
    electronic: "Electronic",
  },
  {
    hiphop: "Hip Hop",
  },
  {
    rock: "Rock",
    metal: "Metal",
    alternative: "Alternative",
  },
  {
    latin: "Latin",
    reggae: "Reggae",
  },
  {
    jazzblues: "Jazz & Blues",
    rbsoul: "R&B & Soul",
    classical: "Classical",
    piano: "Piano",

  },
  {
    country: "Country",
    folk: "Folk",
  },
  {
    disco: "Disco",
    indie: "Indie",
    world: "World",
  },
  {
    pop: "Pop",
    other: "Other",
  }
];