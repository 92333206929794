<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { user } from "./auth.js";
  import { api } from "../../lib/api";
  import { genres } from "../../lib/store.js";
  import sortBy from "lodash/sortBy";

  // Set page and nav titles
  $: metatags.title = "Favorite Performers • NightLife.FM";
  navTitle.set("Favorite Performers");

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  let performersLikedPromise = fetchItemsLiked("performer", $user);

  // Gets liked Performers and Venues
  async function fetchItemsLiked(type, user) {
    const data = await api.items(type + "s_liked").readMany({
      filter: {
        user_created: {
          _eq: user.id,
        },
      },
      // performer: {
      //   sort: "name",
      // },
      fields: ["performer.*"],
    });

    // Limit number of genres to 3
    data["data"].forEach((p, i) => {
      if (p.performer.genres) {
        data["data"][i].performer.genres.splice(3);
      }
    });

    return sortBy(data["data"], function (item) {
      return [item.performer.name];
    });
  }
</script>

<div class="container">
  {#await performersLikedPromise then performers}
    <ul class="mx-3 mt-2">
      {#each performers as performer}
        <li class="mb-3">
          <a
            href="/performers/{performer.performer.slug}"
            class="h-16 flex rounded-lg border border-gray-900 border-opacity-75 bg-gray-900 bg-opacity-50"
          >
            <span class="flex-none w-16 mr-3">
              {#if performer.performer.image}
                <img
                  src={api._transport._url +
                    "/assets/" +
                    performer.performer.image +
                    "?key=thumbnail"}
                  alt={performer.performer.name}
                  width="64"
                  height="64"
                  style="filter: grayscale(100%)"
                  class="block rounded-l-lg"
                />
              {:else}
                <span
                  class="material-icons align-middle opacity-50"
                  style="font-size: 2rem; padding: 1rem;"
                >
                  headset
                </span>
              {/if}
            </span>
            <span class="flex-auto overflow-hidden align-top">
              <span class="flex flex-col mt-2">
                <span class="text-lg font-bold truncate"
                  >{performer.performer.name}</span
                >
                {#if performer.performer.genres}
                  <span class="-mt-2">
                    {#each performer.performer.genres as genre}
                      {#if genres[genre] != undefined}
                        <span class="text-gray-500 text-xs mr-2"
                          >{genres[genre]}</span
                        >
                      {/if}
                    {/each}
                  </span>
                {/if}
              </span>
            </span>
          </a>
        </li>
      {/each}
    </ul>
  {/await}
</div>
