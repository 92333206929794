<script>
  import { metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { user } from "../user/auth.js";
  import { api } from "../../lib/api";
  import { genres } from "../../lib/store.js";
  import sortBy from "lodash/sortBy";

  // Set page and nav titles
  $: metatags.title = "All Performers • NightLife.FM";
  navTitle.set("All Performers");

  let performersPromise = fetchPerformers();

  // Gets liked Performers and Venues
  async function fetchPerformers() {
    const data = await api.items("performers").readMany({
      filter: {
        status: {
          _eq: "published",
        },
      },
      sort: "name",
    });

    // Limit number of genres to 3
    data["data"].forEach((performer, i) => {
      if (performer.genres) {
        data["data"][i].genres.splice(3);
      }
    });
    return data["data"];
  }
</script>

<div class="container">
  {#await performersPromise then performers}
    <ul class="mx-3 mt-2">
      {#each performers as performer}
        <li>
          <a href="/performers/{performer.slug}" class="flex h-16 mb-3 rounded-lg border border-gray-900 border-opacity-75 bg-gray-900 bg-opacity-50">
            <span class="flex-none w-16 mr-3">
              {#if performer.image}
                <img
                  src={api._transport._url + "/assets/" + performer.image + "?key=thumbnail"}
                  alt={performer.name}
                  width="64"
                  height="64"
                  style="filter: grayscale(100%)"
                  class="block rounded-l-lg"
                />
              {:else}
                <span class="material-icons align-middle opacity-50" style="font-size: 2rem; padding: 1rem"> headset </span>
              {/if}
            </span>
            <span class="flex-auto overflow-hidden align-top">
              <span class="flex flex-col mt-2">
                <span class="text-lg font-bold truncate">{performer.name}</span>
                {#if performer.genres}
                  <span class="-mt-2">
                    {#each performer.genres as genre}
                      {#if genres[genre] != undefined}
                        <span class="text-gray-500 text-xs mr-2">{genres[genre]}</span>
                      {/if}
                    {/each}
                  </span>
                {/if}
              </span>
            </span></a
          >
        </li>
      {/each}
    </ul>
  {/await}
</div>
