<script>
  import { goto } from "@sveltech/routify";
  import { navTitle } from "../lib/store.js";
  import { Button, Dialog, List } from "smelte";
  import { authenticatedRole, adminRole } from "../lib/api";
  import { user } from "../pages/user/auth.js";
  import MetroFilter from "./venues/MetroFilter.svelte";

  let showDialog = false;
  let menuList = [];
  let y = 0;
  $: navBG = y <= 40 ? "" : "bg-black border-b border-gray-800";

  user.subscribe((value) => {
    if (value) {
      if (value.first_name == null || value.last_name == null) {
        $goto("/user/update-name");
      }
      if (value.role == authenticatedRole) {
        menuList = [
          {
            text: "Favorite Performers",
            icon: "favorite",
            to: "/user/favorite-performers",
          },
          {
            text: "Favorite Venues",
            icon: "favorite",
            to: "/user/favorite-venues",
          },
          {
            text: "Help",
            icon: "help",
            to: "/help",
          },
          {
            text: "Settings",
            icon: "settings",
            to: "/user/account",
          },
          {
            text: "Log out",
            icon: "logout",
            to: "/user/logout",
          },
        ];
        if (value.performer) {
          menuList.unshift(
            {
              text: "My Profile",
              icon: "headset",
              to: "/performers/" + value.performer,
            },
            {
              text: "Statistics",
              icon: "show_chart",
              to: "/performers/" + value.performer + "/stats",
            }
          );
        } else {
          menuList.unshift({
            text: "Register as a performer",
            icon: "headset",
            to: "/user/register-as-performer",
          });
        }
      } else if (value.role == adminRole) {
        menuList = [
          {
            text: "Import Venues",
            icon: "system_update_alt",
            to: "/admin/import-venues",
          },
          {
            text: "Log out",
            icon: "logout",
            to: "/user/logout",
          },
        ];
      }
    } else {
      menuList = [
        {
          text: "Log in",
          icon: "input",
          to: "/user/login",
        },
        {
          text: "Sign up",
          icon: "person_add",
          to: "/user/signup",
        },
        {
          text: "Help",
          icon: "help",
          to: "/help",
        },
      ];
    }
  });
</script>

<svelte:window bind:scrollY={y} />

<nav class="sticky top-0 container z-30 flex h-full items-center justify-between mx-auto p-3 {navBG}">
  <div class="flex-grow-0">
    <a href="/">
      <img id="logo" alt="logo" src="/images/NightLife.png" class="h-10 w-10 mr-3" />
    </a>
  </div>
  <div class="flex-grow text-base tracking-tight">
    <span class="bg-black bg-opacity-50 rounded-sm shadow px-2 py-2">
      {#if typeof $navTitle === "string"}
        <span class="font-bold tracking-normal">{$navTitle}</span>
      {:else}
        <MetroFilter />
      {/if}
    </span>
  </div>

  {#if $user == null || ($user.first_name == null && $user.last_name == null)}
    <div class="flex-grow-0">
      <button
        class="bg-black bg-opacity-50 border border-opacity-50 rounded-full h-10 w-10 flex items-center
          justify-center text-xs"
        on:click={() => (showDialog = true)}
      >
        <span class="material-icons"> account_circle </span>
      </button>
    </div>
  {:else}
    <div class="flex-grow-0">
      <button
        class="bg-black bg-opacity-50 border border-opacity-50 rounded-full h-10 w-10 flex items-center
          justify-center text-xs"
        on:click={() => (showDialog = true)}
      >
        {#if $user.first_name != null && $user.last_name != null}
          {$user.first_name.substr(0, 1) + $user.last_name.substr(0, 1)}
        {:else}
          {#if $user.first_name != null}
            {$user.first_name.substr(0, 1)}
          {/if}
          {#if $user.last_name != null}
            {$user.last_name.substr(0, 1)}
          {/if}
        {/if}
      </button>
    </div>
  {/if}
</nav>

<Dialog bind:value={showDialog} navigation="true" opacity="0.9" class="ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4">
  <div slot="title" class="pl-4 font-normal">
    {#if $user != null}
      {$user.first_name} {$user.last_name}
    {:else}
      <a href="/" on:click={() => (showDialog = false)}>NightLife.FM</a>
    {/if}
  </div>
  <div class="text-sm ml-4 text-gray-800">
    {#if $user != null}{$user.email}{/if}
  </div>
  <!-- binding to List component event did not work, using div -->
  <div on:click={() => (showDialog = false)}>
    <List items={menuList} />
  </div>
  <div slot="actions">
    <Button on:click={() => (showDialog = false)} text color="white" class="hover:bg-gray-transDark" icon="cancel" iconClass="mr-3">Cancel</Button>
  </div>
</Dialog>

<style>
  div :global(i.account-button-icon) {
    font-size: 2.5rem;
  }
</style>
