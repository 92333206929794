<script>
  import { Button, DatePicker, Dialog, Checkbox } from "smelte";
  import Picker from "smelte/src/components/DatePicker/Picker.svelte";
  import { onMount } from "svelte";
  import { venuesFilter } from "../components/venues/filters";
  import { api, getDistance } from "../lib/api";
  import { user } from "./user/auth.js";
  import { navTitle } from "../lib/store.js";
  import { metatags } from "@sveltech/routify";
  import EventsByGenre from "../components/venues/EventsByGenre.svelte";
  import EventsFavorites from "../components/venues/EventsFavorites.svelte";
  import FeaturedPerformers from "../components/venues/FeaturedPerformers.svelte";
  import LatestPerformers from "../components/venues/LatestPerformers.svelte";
  import LatestVenues from "../components/venues/LatestVenues.svelte";

  $: metatags.title = "NightLife.FM";
  let venuesFilterPromise = fetchVenueFilter();

  onMount(() => {
    if ($venuesFilter.date == null) {
      $venuesFilter.date = new Date();
    }
  });

  async function fetchVenueFilter() {

    // Reset filter every 12 hours
    let expireDate = new Date();
    if ($venuesFilter.updated == undefined || Date.parse($venuesFilter.updated) < expireDate.setHours(expireDate.getHours() - 12)) {
      $venuesFilter.date = null;
      $venuesFilter.lat = null;
      $venuesFilter.lon = null;
      $venuesFilter.metro = null;
      $venuesFilter.neighborhoods = ["Any Neighborhood"];
      $venuesFilter.updated = new Date();
    }

    // Set Metro area
    if (typeof $navTitle === "string") {
      if ($venuesFilter.metro !== null) {
        $navTitle = $venuesFilter.metro;
      } else {
        // Start with a sensible default
        $venuesFilter.metro = {
          name: "Washington, DC",
          id: "a3be4735-adb3-447e-86bb-2fc3e57bb5d0",
          geo: {
            lat: 38.8899772912989,
            lng: -77.01012611389162,
          },
          slug: "washington-dc",
        };

        $navTitle = {
          name: "Washington, DC",
          id: "a3be4735-adb3-447e-86bb-2fc3e57bb5d0",
          geo: {
            lat: 38.8899772912989,
            lng: -77.01012611389162,
          },
          slug: "washington-dc",
        };
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition((position) => {
            $venuesFilter.lat = position.coords.latitude;
            $venuesFilter.lon = position.coords.longitude;
            api
              .items("cities")
              .readMany({
                filter: {
                  status: {
                    _eq: "published",
                  },
                },
                fields: ["*"],
              })
              .then((data) => {
                let closestMetro = null;
                let minDistance = 99999;
                data.data.forEach((metro, i) => {
                  let thisDistance = getDistance(
                    $venuesFilter.lat,
                    $venuesFilter.lon,
                    metro.geo.lat,
                    metro.geo.lng
                  );
                  if (thisDistance < minDistance) {
                    minDistance = thisDistance;
                    closestMetro = data.data[i];
                  }
                });
                $venuesFilter.metro = closestMetro;
                $navTitle = closestMetro;
              });
          });
        } else {
          // @TODO: determine metro area from the IP address
        }
      }
    }
  }

  let showDialogDate = false;
  $: properDate = new Date($venuesFilter.date);
  $: formattedDate = Intl.DateTimeFormat("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  }).format(new Date($venuesFilter.date));

  let showDialogHoods = false;
  $: neighborhoodsPromise = fetchNeighborhoods($venuesFilter.metro.id);
  $: selectedHoods = $venuesFilter.neighborhoods;

  async function fetchNeighborhoods(metroID) {
    const data = await api.items("venues").readMany({
      filter: {
        _and: [
          {
            status: {
              _eq: "published",
            },
          },
          {
            metro: {
              _eq: metroID,
            },
          },
        ],
      },
      fields: ["areaserved"],
    });
    let hoods = [];
    data.data.forEach((areas, i) => {
      if (areas.areaserved) {
        hoods = hoods.concat(areas.areaserved);
      }
    });
    hoods = hoods.filter((x, i, a) => a.indexOf(x) == i);
    hoods.sort();
    hoods.unshift("Any Neighborhood");
    return hoods;
  }

  function toggle(i) {
    // Uncheck everything else if Any is selected
    if (i === "Any Neighborhood") {
      selectedHoods = ["Any Neighborhood"];
      return;
    }

    // Uncheck Any if it was the only option selected when something else is also selected
    if (
      i !== "Any Neighborhood" &&
      selectedHoods.length == 1 &&
      selectedHoods[0] === "Any Neighborhood"
    ) {
      selectedHoods.shift("Any Neighborhood");
    }

    return (v) =>
      (selectedHoods = v.detail
        ? selectedHoods.concat(i)
        : selectedHoods.filter((si) => si !== i));
  }

  function submitFormNeighborhoods() {
    if (selectedHoods.length === 0) {
      selectedHoods.unshift("Any Neighborhood");
    }
    $venuesFilter.neighborhoods = selectedHoods;
    $venuesFilter.neighborhoods = $venuesFilter.neighborhoods;
    venuesFilter.set($venuesFilter);
    showDialogHoods = false;
  }

  function cancelFormNeighborhoods() {
    selectedHoods = $venuesFilter.neighborhoods;
    showDialogHoods = false;
  }
</script>

<div class="container mx-auto h-full items-center p-3">
  {#await venuesFilterPromise then filter}
    <div class="flex mb-8">
      <Button
        on:click={() => (showDialogDate = !showDialogDate)}
        small
        outlined
        remove="hover:bg-white uppercase z-10"
        color="white"
        add="flex-grow-0 rounded-full text-xs pl-4 pr-4"
      >
        {formattedDate}
      </Button>

      <Dialog
        bind:value={showDialogDate}
        opacity="0.9"
        class="pt-0 pr-0 pb-0 pl-0 w-screen md:w-1/2 lg:w-1/4"
        titleClasses="hidden"
        actionsClasses="hidden"
      >
        <div class="w-full absolute top-0 mt-32">
          <Picker
            value={properDate}
            on:change={(i) => ($venuesFilter.date = i.detail)}
            on:change={() => {
              showDialogDate = false;
            }}
            class=""
          />
        </div>
      </Dialog>

      <Button
        on:click={() => (showDialogHoods = true)}
        small
        outlined
        remove="hover:bg-white uppercase z-10"
        color="white"
        add="flex-grow rounded-full ml-3 text-xs"
      >
        {#if $venuesFilter.neighborhoods != undefined}
          {$venuesFilter.neighborhoods.join(", ").substring(0, 31)}
        {:else}Any Neighborhood{/if}
      </Button>

      <Dialog
        bind:value={showDialogHoods}
        opacity="0.9"
        class="pt-2 pb-8 w-screen h-screen md:w-1/2 lg:w-1/4 overflow-y-scroll"
      >
        <div class="text-lg mb-2 ml-3">Neighborhoods</div>
        {#await neighborhoodsPromise then neighborhoods}
          <ul class="checkboxes">
            {#each neighborhoods as hood}
              <li class="box-border w-1/2 m-0">
                <Checkbox
                  checked={selectedHoods.includes(hood)}
                  classes="inline-flex items-center cursor-pointer z-10 text-xs whitespace-no-wrap"
                  label={hood}
                  on:change={toggle(hood)}
                />
              </li>
            {/each}
          </ul>
        {/await}
        <div slot="actions" class="w-full inline-flex mb-12">
          <Button
            on:click={submitFormNeighborhoods}
            small
            outlined
            remove="hover:bg-white"
            color="white"
            class="rounded-full flex-1 ml-3 mr-1">Submit</Button
          >
          <Button
            on:click={cancelFormNeighborhoods}
            small
            text
            color="white"
            remove="hover:bg-white"
            class="flex-1 ml-1 mr-3"
            icon="cancel"
            iconClass="mr-3">Cancel</Button
          >
        </div>
      </Dialog>
    </div>
    {#if $user != undefined}
      <EventsFavorites user={$user} venuesFilter={$venuesFilter} />
    {:else}
      <div
        class="container bg-gray-900 bg-opacity-50 rounded-lg mb-8 px-3 pt-3 pb-3 text-sm"
      >
        <a
          href="/user/login"
          class="block rounded-lg border border-gray-800 border-opacity-50 bg-gray-800 bg-opacity-25"
        >
          <span class="block py-3 text-center uppercase text-xs font-bold"
            >Log in</span
          >
        </a>
      </div>
    {/if}

    <EventsByGenre venuesFilter={$venuesFilter} />
    <FeaturedPerformers venuesFilter={$venuesFilter} />
    <LatestPerformers venuesFilter={$venuesFilter} />
    <LatestVenues venuesFilter={$venuesFilter} />
  {/await}
</div>
