<script>
  import { goto, metatags, params } from "@sveltech/routify";
  import { navTitle, days } from "../../lib/store.js";
  import { Button, DatePicker, Dialog, Select, TextField } from "smelte";
  // Use once TimePicker package is stable
  // import TimePicker from "svelte-time-picker/bare.js";
  import { api } from "../../lib/api";
  import { user } from "../user/auth.js";
  import slug from "slug";

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  let title = "NightLife.FM";
  let performerID = null;
  let performerOwner = null;
  let venues = [];
  let eventDate = null;
  let eventHour = 10;
  let eventMinute = "00";
  let eventAMPM = "PM";
  let eventVenue = null;
  let ticketsLink = null;
  let performerPromise = fetchPerformer();
  let venuesPromise = fetchVenues();

  let hour = [];
  for (let i = 0; i <= 12; i++) {
    hour.push({ value: i, text: i });
  }

  // Set page and nav titles
  $: metatags.title = title;
  navTitle.set(title);
  let residencyDays = days;

  async function fetchPerformer() {
    const data = await api.items("performers").readMany({
      filter: {
        id: {
          _eq: $params.performer,
        },
      },
    });
    title = data["data"][0].name;
    navTitle.set(title);
    performerID = data["data"][0].id;
    performerOwner = data["data"][0].user_created;
    residencyDays.forEach((venue) => {
      residencyDays[venue.num].value =
        data["data"][0]["residency_day_" + venue.num];
    });
    return data["data"][0];
  }

  async function fetchVenues() {
    const data = await api.items("venues").readMany({
      filter: {
        status: {
          _eq: "published",
        },
      },
      sort: "name",
      limit: -1,
      fields: [
        "id",
        "name",
        "addresslocality",
        "addressregion",
        "addresscountry",
      ],
    });
    data.data.forEach((venue) => {
      venues.push({
        value: venue.id,
        text:
          venue.name +
          ", " +
          venue.addresslocality +
          ", " +
          venue.addressregion +
          ", " +
          venue.addresscountry.toUpperCase(),
      });
    });
    return data.data;
  }

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  function addEventFormSubmit(e) {
    let myDate = new Date(eventDate);
    let hour24 =
      eventAMPM == "AM"
        ? eventHour.toString()
        : (parseInt(eventHour) + 12).toString();
    hour24 = hour24.length < 2 ? "0" + hour24 : hour24;
    myDate.setHours(hour24);
    myDate.setMinutes(eventMinute);

    let formattedDate = Intl.DateTimeFormat("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    }).format(myDate);

    let eventSlug = slug(eventVenue + "-" + formattedDate);

    api
      .items("events")
      .createOne({
        startdate: myDate.toISOString(),
        venue: eventVenue,
        performers: [{ performers_id: { id: performerID } }],
        ticketurl: ticketsLink,
        slug: eventSlug,
        status: "published",
      })
      .then((result) => {
        showDialog = true;
        dialogError.message = "Event saved";
        messageType = "";
      })
      .catch((error) => {
        showDialog = true;
        dialogError.message = error.message;
      });
  }
  function cancelAddEvent() {
    window.history.back();
  }
</script>

{#await Promise.all( [performerPromise, venuesPromise] ) then [performerObject, venuesObject]}
  {#if performerOwner == $user.id}
    <form on:submit|preventDefault class="container m-auto md:max-w-sm">
      <div class="text-lg mt-6 mb-3 ml-6">Add event</div>
      <div class="px-6 text-sm">
        <Select
          bind:value={eventVenue}
          outlined
          autocomplete
          label="Venue"
          items={venues}
          listClasses="bg-gray-900"
        />
      </div>
      <div class="px-6 mb-6">
        <div class="mb-4">
          <DatePicker
            required
            on:change={(i) => {
              eventDate = i.detail;
              return false;
            }}
          />
          <!--TimePicker on:change={myCallback} /-->
        </div>
        <div class="flex">
          <Select
            bind:value={eventHour}
            required
            label="Hour"
            dense
            items={hour}
          />
          <Select
            bind:value={eventMinute}
            required
            label="Minute"
            dense
            items={[
              { value: "00", text: "00" },
              { value: "30", text: "30" },
            ]}
          />
          <Select
            bind:value={eventAMPM}
            required
            label="AM/PM"
            dense
            items={[
              { value: "AM", text: "AM" },
              { value: "PM", text: "PM" },
            ]}
          />
        </div>
      </div>

      <div class="px-6">
        <TextField
          label="Tickets Link"
          required="false"
          outlined
          prepend="link"
          bind:value={ticketsLink}
          />
      </div>

      <div class="text-center px-6 mt-12">
        <Button on:click={addEventFormSubmit} class="rounded-full">Save</Button>
        <Button text on:click={cancelAddEvent}>Cancel</Button>
      </div>
    </form>

    <Dialog
      bind:value={showDialog}
      opacity="0.85"
      class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
    >
      <div class="{messageType} text-center">{dialogError.message}</div>
      <div slot="actions">
        <Button text on:click={cancelAddEvent} color="white">OK</Button>
      </div>
    </Dialog>
  {:else}
    <div class="container mx-4 text-center">
      <div class="mt-32 mb-24 text-error-500">Not found</div>
      <div class="clearfix">
        <Button href="/" block outlined>HOME</Button>
      </div>
    </div>
  {/if}
{:catch error}
  <div class="e404">
    {#if error.message == "data.data[0] is undefined"}
      <div class="mb-24 text-error-500">Page not found</div>
      {#if !$user}
        <Button
          href="/user/login?destination={window.location.pathname}"
          block
          outlined>Log in</Button
        >
      {:else}
        <div>
          <Button href="/" block outlined>HOME</Button>
        </div>
      {/if}
    {:else}
      <p class="text-error-500">{error.message}</p>
    {/if}
  </div>
{/await}
