<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button, Dialog, List, TextField } from "smelte";
  import { api } from "../../lib/api";
  import { user } from "./auth.js";

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  // Set page and nav titles
  $: metatags.title = "Update name • NightLife.FM";
  navTitle.set("NightLife.FM");

  let updateNameFormState = {
    first_name: $user.first_name,
    last_name: $user.last_name,
  };

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  function updateNameFormSubmit(event) {
    api.users.me
      .update({
        first_name: updateNameFormState.first_name,
        last_name: updateNameFormState.last_name,
      })
      .then((result) => {
        // update user data in the storage
        user.set(result);
        showDialog = true;
        dialogError.message = "Changes saved";
        messageType = "";
      })
      .catch((error) => {
        showDialog = true;
        dialogError.message = error.message;
      });
  }
</script>

<form on:submit|preventDefault class="container m-auto mt-12 md:max-w-sm">
  <div class="text-lg ml-6 mb-6">Update name</div>

  <div class="px-6">
    <TextField
      label="First name"
      required="true"
      outlined
      prepend="account_circle"
      bind:value={updateNameFormState.first_name}
    />

    <TextField
      label="Last name"
      required="true"
      outlined
      prepend="account_circle"
      bind:value={updateNameFormState.last_name}
    />
  </div>

  <div class="text-center px-6 mt-16">
    <Button on:click={updateNameFormSubmit} class="rounded-full">Save</Button>
    <Button text href="/user/account">Cancel</Button>
    <List
      items={[
        { text: "Account", to: "/user/account" },
        { text: "Home", to: "/" },
      ]}
      dense
      classes="mt-16 -ml-3"
    />
  </div>
</form>

<Dialog
  bind:value={showDialog}
  opacity="0.85"
  class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
>
  <div class="{messageType} text-center">{dialogError.message}</div>
  <div slot="actions">
    <Button text href="/" color="white">OK</Button>
  </div>
</Dialog>
