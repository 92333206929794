<script>
  import { api } from "../../lib/api";

  export let venuesFilter;

  let selectedHoods;

  $: venuesPromise = fetchVenues(venuesFilter);
  async function fetchVenues(venuesFilter) {
    let hoodsFilter = [];
    if (venuesFilter.neighborhoods[0] !== "Any Neighborhood") {
      venuesFilter.neighborhoods.forEach((hood) => {
        hoodsFilter.push({
          areaserved: {
            _contains: hood,
          },
        });
      });
      hoodsFilter = {
        _or: hoodsFilter,
      };
    }

    selectedHoods = hoodsFilter;

    const data = await api.items("venues").readMany({
      filter: {
        _and: [
          {
            status: {
              _eq: "published",
            },
          },
          {
            metro: {
              _eq: venuesFilter.metro.id,
            },
          },
          hoodsFilter,
        ],
      },
      sort: hoodsFilter ? "name" : "-date_created",
      limit: hoodsFilter.length != 0 ? -1 : 10,
      fields: ["name", "areaserved", "slug"],
    });

    return data.data;
  }
</script>

{#await venuesPromise then venues}
  <div class="container bg-gray-900 bg-opacity-50 rounded-lg pt-4 pr-0 pb-1 pl-3">
    <div class="flex ml-2 mr-4 mb-3">
      <div class="flex-grow mr-3  uppercase text-xs font-bold">
        {#if selectedHoods}
          Venues in {venuesFilter.neighborhoods.join(", ")}
        {:else}
          Latest venues
        {/if}
      </div>
    </div>

    <ul>
      {#each venues as venue}
        <li class="mr-3 mb-3">
          <a href="/venues/{venue.slug}" class="block rounded-lg py-3 px-4 border border-white border-opacity-10 bg-gray-800 bg-opacity-25 ">
            <span class="block font-bold">{venue.name}</span>
            {#if venue.areaserved}
              <span class="block text-xs opacity-75">{venue.areaserved.join(", ")}</span>
            {:else}<span class="block text-xs">&nbsp;</span>{/if}
          </a>
        </li>
      {/each}
      <li class="mr-3 mb-3">
        <a href="/venues/all?metro={venuesFilter.metro.slug}" class="block rounded-lg border border-white border-opacity-10 bg-gray-800 bg-opacity-25" style="height: 64px">
          <span class="block mt-5 text-center uppercase text-xs font-bold">See all venues in {venuesFilter.metro.name}</span>
        </a>
      </li>
    </ul>
  </div>
{/await}
