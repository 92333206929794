<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button, Dialog, List, TextField } from "smelte";
  import { api } from "../../lib/api";
  import { user } from "./auth.js";

  // Redirect to homepage if already logged in
  if ($user) {
    $goto("/");
  }

  // Set page and nav titles
  $: metatags.title = "Request password reset • NightLife.FM";
  navTitle.set("NightLife.FM");

  let requestPasswordFormState = {
    email: "",
  };

  let showDialog = false;
  let dialogText = "";
  let messageClass = "text-error-500";

  async function requestPasswordFormSubmit(event) {
    const response = await api.transport.axios
      .post("/auth/password/request", {
        email: requestPasswordFormState.email.trim(),
        reset_url: __config.env.WEB_URL + "/user/reset-password",
      })
      .then(function (result) {
        showDialog = true;
        dialogText = "Please check your email for the reset password message";
        messageClass = "";
      })
      .catch((error) => {
        showDialog = true;
        dialogText = error.message;
      });
  }
</script>

<div class="request-password container m-auto md:max-w-sm">
  <form on:submit|preventDefault class="container m-auto mt-12 md:max-w-sm ">
    <div class="text-lg ml-6 mb-6">Request password reset</div>
    <!--ListErrors {errors} /-->
    <div class="px-6">
      <TextField
        type="email"
        label="Email"
        outlined
        prepend="email"
        bind:value={requestPasswordFormState.email}
      />
    </div>
    <div class="px-6">
      <Button on:click={requestPasswordFormSubmit} class="rounded-full"
        >Request</Button
      >
      <Button text href="/">Cancel</Button>
    </div>
  </form>

  <div class="px-6 mt-10">
    <List
      items={[
        { text: "Log in", to: "/user/login" },
        { text: "Sign up", to: "/user/signup" },
      ]}
      dense
      classes="mt-16 -ml-3"
    />
  </div>
</div>

<Dialog
  bind:value={showDialog}
  opacity="0.85"
  class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
>
  <div class="{messageClass} text-center">{dialogText}</div>
  <div slot="actions">
    <Button class="rounded-full" on:click={() => (showDialog = false)}>
      OK
    </Button>
  </div>
</Dialog>

<style>
  .request-password {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
</style>
