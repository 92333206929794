<script>
  import { Button } from "smelte";
  import { genres } from "../../lib/store.js";
  import { api } from "../../lib/api";

  export let data;

  let toggles = {
    icon: {
      closed: "unfold_more",
      opened: "unfold_less",
    },
    container: {
      closed: "flex overflow-x-scroll",
      opened: "",
    },
    href: {
      closed: "flex-shrink-0",
      opened: "w-full",
    },
    img: {
      closed: "rounded-lg",
      opened: "rounded-l-lg max-h-32",
    },
    imgSize: {
      closed: data.size,
      opened: "6",
    },
    performer: {
      closed:
        data.size == 7
          ? "absolute bottom-0 rounded-b-lg bg-black bg-opacity-75 h-12 w-full pl-2 pt-1"
          : "absolute bottom-0 rounded-b-lg bg-black bg-opacity-75 h-10 w-full pl-2 pt-1",
      opened: "flex align-top py-3 pl-4",
    },
    performerName: {
      closed: data.size == 7 ? "text-sm truncate" : "text-xs truncate",
      opened: "leading-none flex mb-2",
    },
    venueName: {
      closed: data.size == 7 ? "text-sm truncate" : "text-xs truncate",
      opened: "flex mb-2",
    },
    genre: {
      closed: "hidden",
      opened: "",
    },
  };

  let numPerformers = 0;
  data.events.forEach((event) => {
    event.performers.forEach((performer) => {
      numPerformers++;
    });
  });

  let closed = numPerformers < 3 ? "opened" : "closed";

  function toggle() {
    closed = closed == "closed" ? "opened" : "closed";
  }

  function formatEventDate(eventDate) {
    let date = new Date(eventDate);
    let weekDay = Intl.DateTimeFormat("en-US", {
      weekday: "short",
    }).format(date);
    let monthDay = Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
    }).format(date);

    return weekDay + "<br />" + monthDay;
  }
</script>

{#if numPerformers > 0}
  <div
    class="container bg-gray-900 bg-opacity-50 rounded-lg mb-8 pt-4 pr-0 pb-1 pl-3">
    <div on:click={toggle} class="flex ml-2 mr-2 mb-2">
        <span
          class="flex-grow -mt-1 truncate uppercase text-lg font-bold">{@html data.title}</span>
        <div class="flex-shrink-0">
          <Button
            small
            text
            icon={toggles.icon[closed]}
            color="white"
            remove="px-4 text-sm font-medium z-10 uppercase"
            add="text-xs rounded-full bg-none hover:bg-opacity-0 -mt-1 align-top" />
        </div>
    </div>
    <div class={toggles.container[closed]}>
      {#if data.events}
        {#each data.events as event}
          {#each event.performers as performer}
            {#if performer.performers_id !== null}
              <a
                href="/performers/{performer.performers_id.slug}"
                class="{toggles.href[closed]} block relative rounded-lg border box-border border-white border-opacity-10 bg-gray-800 bg-opacity-25 mr-3 mb-3"
              >
                <span class="flex">
                  <span class="flex flex-none">
                    {#if performer.performers_id.image}
                      <img
                        src={api._transport._url + '/assets/' + performer.performers_id.image + '?key=thumbnail'}
                        alt={performer.performers_id.name}
                        width="128"
                        height="128"
                        class={toggles.img[closed]}
                        style="filter: grayscale(100%)" />
                    {:else}
                      <span class="material-icons align-middle opacity-50" style="font-size: 4rem; padding: 2rem"> headset </span>
                    {/if}
                    <span class="block absolute top-0 left-0 p-1 mt-2 ml-2 rounded bg-black opacity-60 text-xs leading-tight">{@html formatEventDate(event.startdate)}</span>
                  </span>
                  <span class={toggles.performer[closed]}>
                    <span class="block">
                      <span
                        class="{toggles.performerName[closed]} block font-bold">{performer.performers_id.name}</span>
                      <span
                        class="{toggles.venueName[closed]} block font-bold leading-none">{event.venue.name}</span>
                      {#if performer.performers_id.genres}
                        <div class="hidden">
                          {performer.performers_id.genres.splice(3)}
                        </div>
                        <span class={toggles.genre[closed]}>
                          {#each performer.performers_id.genres as genre}
                            {#if genres[genre] != undefined}
                              <span
                                class="block opacity-75 text-xs">{genres[genre]}</span>
                            {/if}
                          {/each}
                        </span>
                      {/if}
                      <!--span class="block absolute bottom-0 right-0 mr-2 mb-2">
                    <Button
                      small
                      href="/"
                      icon="confirmation_number" />
                  </span-->
                    </span>
                  </span>
                </span></a>
            {/if}
          {/each}
        {/each}
      {/if}
    </div>
  </div>
{/if}

