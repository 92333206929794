<script>
  import { api, getEventsDateFilter } from "../../lib/api";
  import Events from "./Events.svelte";
  import uniqBy from "lodash/uniqBy";

  export let user;
  export let venuesFilter;

  $: eventsPromise = fetchEvents(user, venuesFilter);
  async function fetchEvents(user, venuesFilter) {
    const performersLiked = await fetchItemsLiked("performer", user);
    const venuesLiked = await fetchItemsLiked("venue", user);

    let dateFilter;
    if (false) {
      // Show events for tonight even after midnight
      dateFilter = getEventsDateFilter("tonight");
    } else {
      let d = new Date(venuesFilter.date);
      let start =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " 00:00:00";

      let end =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " 23:59:59";

      dateFilter = {
        _between: start + "," + end,
      };
    }

    let hoodsFilter = [];
    if (venuesFilter.neighborhoods[0] !== "Any Neighborhood") {
      venuesFilter.neighborhoods.forEach((hood) => {
        hoodsFilter.push({
          venue: {
            areaserved: {
              _contains: hood,
            },
          },
        });
      });
      hoodsFilter = {
        _or: hoodsFilter,
      };
    }

    // Fetch events for liked performers
    let events1 = { data: [] };
    let events3 = [];
    if (performersLiked.length > 0) {
      events1 = await api.items("events").readMany({
        filter: {
          _and: [
            {
              status: {
                _eq: "published",
              },
            },
            {
              startdate: dateFilter,
            },
            {
              performers: {
                performers_id: {
                  id: {
                    _in: performersLiked,
                  },
                },
              },
            },
            {
              venue: {
                metro: {
                  _eq: venuesFilter.metro.id,
                },
              },
            },
            hoodsFilter,
          ],
        },
        fields: ["*", "venue.*", "performers.*.*"],
      });
      events3 = await fetchResidents("performers", performersLiked);
    }

    // The following is problematic due to combining with events2 and unique, disabling for now
    // Remove non-favorite performers from the events for liked performers
    // events1.forEach((event, i) => {
    //   event.performers.forEach((performer, j) => {
    //     if (
    //       performer.performers_id != undefined &&
    //       performersLiked.indexOf(performer.performers_id.id) == -1
    //     ) {
    //       events1[i].performers.splice(j, 1);
    //     }
    //   });
    // });

    // Fetch events for liked venues
    let events2 = { data: [] };
    let events4 = [];
    if (venuesLiked.length > 0) {
      events2 = await api.items("events").readMany({
        filter: {
          _and: [
            {
              status: {
                _eq: "published",
              },
            },
            {
              startdate: dateFilter,
            },
            {
              venue: {
                id: {
                  _in: venuesLiked,
                },
              },
            },
            {
              venue: {
                metro: {
                  _eq: venuesFilter.metro.id,
                },
              },
            },
            hoodsFilter,
          ],
        },
        fields: ["*", "venue.*", "performers.*.*"],
      });
      events4 = await fetchResidents("venues", venuesLiked);
    }

    // Combine events
    let events = [];
    if (events1.data != undefined) {
      events = events.concat(events1.data);
    }
    if (events2.data != undefined) {
      events = events.concat(events2.data);
    }
    events = events.concat(events3);
    events = events.concat(events4);

    // Remove duplicate events using lodash
    events = uniqBy(events, "id");

    // Remove duplicate performers using lodash
    events = uniqBy(events, "performers.performers_id.id");

    return {
      events: events,
      title:
        '<span class="material-icons text-base">favorite</span>&nbsp; Favorites',
      size: 6,
    };
  }

  // Gets the IDs of liked Performers and Venues
  async function fetchItemsLiked(type, user) {
    const data = await api.items(type + "s_liked").readMany({
      filter: {
        user_created: {
          _eq: user.id,
        },
      },
      fields: [type],
    });

    // Convert into an array of item IDs
    let itemIDs = Object.keys(data["data"]).map(function (key) {
      return data["data"][key][type];
    });
    return itemIDs;
  }

  // Gets the IDs of liked Performers and Venues
  async function fetchResidents(itemType, itemsLiked) {
    let d = new Date(venuesFilter.date);
    let residency_day = "residency_day_" + d.getDay();

    let hoodsFilter = [];
    if (venuesFilter.neighborhoods[0] !== "Any Neighborhood") {
      venuesFilter.neighborhoods.forEach((hood) => {
        hoodsFilter.push({
          [residency_day]: {
            areaserved: {
              _contains: hood,
            },
          },
        });
      });
      hoodsFilter = {
        _or: hoodsFilter,
      };
    }

    let likedFilter = null;
    if (itemType == "performers") {
      likedFilter = {
        ["id"]: {
          _in: itemsLiked,
        },
      };
    } else if (itemType == "venues") {
      likedFilter = {
        [residency_day]: {
          id: {
            _in: itemsLiked,
          },
        },
      };
    }

    // Get all performers that have residecy for the day of the week and the residency venue is in the selected city and hood
    const data = await api.items("performers").readMany({
      filter: {
        _and: [
          likedFilter,
          {
            [residency_day]: {
              metro: {
                _eq: venuesFilter.metro.id,
              },
            },
          },
          hoodsFilter,
        ],
      },
      fields: ["*", residency_day + ".*"],
    });

    let events = [];
    data["data"].forEach((resident) => {
      events.push({
        performers: [
          {
            performers_id: resident,
          },
        ],
        venue: resident[residency_day],
      });
    });

    return events;
  }
</script>

{#await eventsPromise then data}
  {#if data.length !== undefined}
    <Events {data} />
  {/if}
{/await}
