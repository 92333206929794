<script>
  import { api } from "../../lib/api";
  import { genres } from "../../lib/store.js";

  export let venuesFilter;

  $: performersPromise = fetchPerformers(venuesFilter);
  async function fetchPerformers(venuesFilter) {
    let dateFilter;

    // city
    // curent date
    let d = new Date();
    let today = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " 00:00:00";

    const featured = await api.items("performers_featured").readMany({
      filter: {
        _and: [
          {
            status: {
              _eq: "published",
            },
          },
          {
            city: {
              _eq: venuesFilter.metro.id,
            },
          },
          {
            from: {
              _lte: today,
            },
          },
          {
            to: {
              _gte: today,
            },
          },
        ],
      },
      sort: "sort",
    });

    if (featured["data"] !== undefined && featured["data"].length > 0) {
      let IDs = [];
      featured["data"].forEach((performer, i) => {
        IDs.push(performer.performer);
      });

      const performers = await api.items("performers").readMany({
        filter: {
          _and: [
            {
              status: {
                _eq: "published",
              },
            },
            {
              id: {
                _in: IDs,
              },
            },
          ],
        },
      });
      return performers;
    } else {
      return [];
    }
  }

  function formatGenres(performerGenres) {
    let formattedGenres = [];
    performerGenres.forEach((genre) => {
      if (genres[genre] != undefined) {
        formattedGenres.push(genres[genre]);
      }
    });
    return formattedGenres.join(", ");
  }
</script>

{#await performersPromise then performers}
  {#if performers["data"] !== undefined && performers["data"].length > 0}
    <div class="container bg-gray-900 bg-opacity-50 border border-blue-500 border-opacity-30 rounded-lg pt-4 pr-0 pb-3 pl-3 mb-6">
      <div class="flex ml-2 mr-4 mb-3">
        <div class="flex-grow mr-3 uppercase text-xs font-bold">Featured performers</div>
      </div>
      <ul class="mr-3 mt-2">
        {#each performers["data"] as performer}
          <li class="mb-3">
            <a href="/performers/{performer.slug}" class="h-16 flex rounded-lg box-border border border-white border-opacity-10 bg-gray-800 bg-opacity-25">
              <span class="flex-none w-16 mr-3">
                {#if performer.image}
                  <img
                    src={api._transport._url + "/assets/" + performer.image + "?key=thumbnail"}
                    alt={performer.name}
                    width="64"
                    height="64"
                    style="filter: grayscale(100%)"
                    class="block rounded-l-lg"
                  />
                {:else}
                  <span class="material-icons align-middle opacity-50" style="font-size: 2rem; padding: 1rem"> headset </span>
                {/if}
              </span>
              <span class="flex-auto overflow-hidden align-top">
                <span class="flex flex-col mt-2">
                  <span class="text-lg font-bold truncate">{performer.name}</span>
                  <span class="block truncate opacity-75 text-xs -mt-1">
                    {#if performer.addresslocality && performer.addresscountry}
                      {performer.addresslocality}, {performer.addresscountry} /
                    {/if}
                    {#if performer.genres}
                      {formatGenres(performer.genres)}
                    {/if}
                  </span>
                </span>
              </span></a
            >
          </li>
        {/each}
      </ul>
    </div>
  {/if}
{/await}
