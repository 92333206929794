import { AxiosTransport, Auth, Directus } from '@directus/sdk';

// export const api = new Directus(__config.env.API_URL, {
//   auth: {
//     storage: window.localStorage,
//     mode: 'json',
//     autoRefresh: true,
//   }
// });

// Storage adapter where authentication state (token & expiration) is stored.
const storage = window.localStorage;
const transport = new AxiosTransport(__config.env.API_URL, storage, async () => {
	await auth.refresh(); // This is how transport.axios checks for refresh
});
const auth = new Auth(transport, storage, {
	mode: 'json',
});
export const api = new Directus(__config.env.API_URL, {
	auth,
	storage,
	transport,
});

export const authenticatedRole = "14a7fb80-23d6-4d13-af24-c56c891d6574";
export const adminRole = "bcec189e-26c7-4de4-87a1-7d083e47713e";

export function getMapboxImageURL(venue) {
  return "https://api.mapbox.com/styles/v1/mapbox/dark-v10/static/pin-l+333(" +
    venue.geo.lng +
    "," +
    venue.geo.lat +
    ")/" +
    venue.geo.lng +
    "," +
    venue.geo.lat +
    ",14,0,0/425x212?access_token=" +
    __config.env.MAPBOX_TOKEN;
}

export const geoapifyURL = "https://api.geoapify.com/v2/places?apiKey=" + __config.env.GEOAPIFY_KEY;

export function getDirectionsURL(venue) {
  return "https://maps.google.com/?q=" +
    venue.name +
    ", " +
    venue.streetaddress +
    ", " +
    venue.addresslocality +
    ", " +
    venue.addressregion +
    ", " +
    venue.postalcode +
    ", " +
    venue.addresscountry.toUpperCase();
}

export function getEventsDateFilter(eventType) {
  let d = new Date();
  let today5AM =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1) +
    "-" +
    d.getDate() +
    " 05:00:00";

  // Set date to tomorrow
  d.setHours(d.getHours() + 24);
  let tomorrow5AM =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1) +
    "-" +
    d.getDate() +
    " 05:00:00";

  // Set date to yesterday
  d.setHours(d.getHours() - 48);
  let yesterday5AM =
    d.getFullYear() +
    "-" +
    (d.getMonth() + 1) +
    "-" +
    d.getDate() +
    " 05:00:00";

  // reset date to now
  d.setHours(d.getHours() + 24);

  let dateFilter = {};
  if (d.getHours() >= 5) {
    if (eventType == "tonight") {
      dateFilter = {
        _between: today5AM + "," + tomorrow5AM,
      };
    } else if (eventType == "future") {
      dateFilter = {
        _gte: tomorrow5AM,
      };
    } else if (eventType == "past") {
      dateFilter = {
        _lt: today5AM,
      };
    }
  } else {
    if (eventType == "tonight") {
      dateFilter = {
        _between: yesterday5AM + "," + today5AM,
      };
    } else if (eventType == "future") {
      dateFilter = {
        _gte: today5AM,
      };
    } else if (eventType == "past") {
      dateFilter = {
        _lt: yesterday5AM,
      };
    }
  }
  let sort = "startdate";
  let dateOptions = {
    month: "short",
    day: "numeric",
  };

  if (eventType == "past") {
    sort = "-startdate";
    dateOptions.year = "numeric";
  }

  return dateFilter;
}

/**
 * Calculates distance in miles (default), K (kilometers), or N (nautical)
 */
export function getDistance(lat1, lon1, lat2, lon2, unit = "M") {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  } else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344
    }
    if (unit == "N") {
      dist = dist * 0.8684
    }
    return dist;
  }
}