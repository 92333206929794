<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../../lib/store.js";
  import { Button, Dialog, List, TextField } from "smelte";
  import { api } from "../../../lib/api";
  import { user } from "../../user/auth.js";
  import Genre from "../../../components/Genres.svelte";

  // Redirect to login page if not logged in
  if (!$user) {
    $goto("/user/login?destination=" + window.location.pathname);
  }

  export let performer;

  let title = "NightLife.FM";
  let performerID = null;
  $: performerGenres = "";
  let performerPromise = fetchPerformer();

  // Set page and nav titles
  $: metatags.title = title;
  navTitle.set(title);

  async function fetchPerformer() {
    const data = await api.items("performers").readMany({
      filter: {
        slug: {
          _eq: performer,
        },
      },
    });
    title = data["data"][0].name;
    navTitle.set(title);
    performerID = data["data"][0].id;
    performerGenres = data["data"][0].genres;
    return data["data"][0];
  }

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  function updateGenreFormSubmit(event) {
    api
      .items("performers")
      .updateOne(performerID, {
        genres: performerGenres,
      })
      .then((result) => {
        dialogError.message = "Changes saved";
        messageType = "";
        $goto("/performers/" + performer);
      })
      .catch((error) => {
        showDialog = true;
        dialogError.message = error.message;
      });
  }
</script>

{#await performerPromise then performerObject}
  {#if performerObject.user_created == $user.id}
    <form on:submit|preventDefault class="container m-auto mx-3 md:max-w-sm">
      <div class="text-lg mt-3 ml-3">Update genre</div>
      <Genre
        bind:value={performerGenres}
        useDialog="false"
        inputFieldHidden="true"
        initialValue={performerObject.genres}
      />
      <div class="text-center px-6 mt-6 mb-12">
        <Button on:click={updateGenreFormSubmit} class="rounded-full"
          >Save</Button
        >
        <Button text href={"/performers/" + performer}>Cancel</Button>
      </div>
    </form>

    <Dialog
      bind:value={showDialog}
      opacity="0.85"
      class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
    >
      <div class="{messageType} text-center">{dialogError.message}</div>
      <div slot="actions">
        <Button text href="/user/account" color="white">OK</Button>
      </div>
    </Dialog>
  {/if}
{:catch error}
  <div class="e404">
    {#if error.message == "data.data[0] is undefined"}
      <div class="mb-24 text-error-500">Page not found</div>
      {#if !$user}
        <Button
          href="/user/login?destination={window.location.pathname}"
          block
          outlined
        >
          Log in
        </Button>
      {:else}
        <div>
          <Button href="/" block outlined>HOME</Button>
        </div>
      {/if}
    {:else}
      <p class="text-error-500">{error.message}</p>
    {/if}
  </div>
{/await}
