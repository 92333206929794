<script>
  import { metatags } from "@sveltech/routify";
  import { navTitle } from "../../../lib/store.js";
  import { api } from "../../../lib/api";
  import { user } from "../../user/auth.js";
  import { Button, DatePicker, Dialog, Select, TextField } from "smelte";
  import slug from "slug";

  export let event;
  let title = "NightLife.FM";
  $: metatags.title = title;
  navTitle.set(title);

  let eventID = null;
  $: eventDate = null;
  $: eventHour = null;
  $: eventMinute = null;
  $: eventAMPM = null;
  $: eventVenue = null;
  let eventPromise = fetchEvent(event);

  let venues = [];
  let venuesPromise = fetchVenues();

  let hours = [];
  for (let i = 0; i <= 12; i++) {
    hours.push({ value: i.toString(), text: i.toString() });
  }

  let ticketsLink;

  let showDialog = false;
  let dialogError = { message: "", details: "" };
  let messageType = "text-error-500";

  let showDeleteDialog = false;

  async function fetchEvent(event) {
    const data = await api.items("events").readMany({
      filter: {
        slug: {
          _eq: event,
        },
      },
      fields: ["*", "venue.*", "performers.performers_id.*"],
    });
    let performerNames = [];
    data["data"][0].performers.forEach((performer) => {
      performerNames.push(performer.performers_id.name);
    });
    eventID = data["data"][0].id;
    eventDate = new Date(data["data"][0].startdate);
    // eventDate = Intl.DateTimeFormat("en-US", {
    //   weekday: "long",
    //   month: "long",
    //   day: "numeric",
    //   year: "numeric",
    // }).format(myDate);

    // let dateParts = eventDate.formatToParts();
    eventHour = Intl.DateTimeFormat("en-US", {
      hour: "numeric",
    })
      .format(eventDate)
      .substr(0, 2)
      .trim()
      .toString();

    eventMinute = Intl.DateTimeFormat("en-US", {
      minute: "numeric",
    })
      .format(eventDate)
      .toString();

    eventAMPM = Intl.DateTimeFormat("en-US", {
      hour: "numeric",
    })
      .format(eventDate)
      .substr(-2);

    eventVenue = data["data"][0].venue.id;

    ticketsLink = data["data"][0].ticketurl;

    return data["data"][0];
  }

  async function fetchVenues() {
    const data = await api.items("venues").readMany({
      filter: {
        status: {
          _eq: "published",
        },
      },
      sort: "name",
      limit: -1,
      fields: [
        "id",
        "name",
        "addresslocality",
        "addressregion",
        "addresscountry",
      ],
    });
    data.data.forEach((venue) => {
      venues.push({
        value: venue.id,
        text:
          venue.name +
          ", " +
          venue.addresslocality +
          ", " +
          venue.addressregion +
          ", " +
          venue.addresscountry.toUpperCase(),
      });
    });
    return data.data;
  }

  function updateEventFormSubmit(e) {
    // patch with date and venue values
    let myDate = new Date(eventDate);
    let hour24 =
      eventAMPM == "AM"
        ? eventHour.toString()
        : (parseInt(eventHour) + 12).toString();
    hour24 = hour24.length < 2 ? "0" + hour24 : hour24;
    myDate.setHours(hour24);
    myDate.setMinutes(eventMinute);

    let formattedDate = Intl.DateTimeFormat("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    }).format(myDate);

    let eventSlug = slug(eventVenue + "-" + formattedDate);

    api
      .items("events")
      .updateOne(eventID, {
        startdate: myDate.toISOString(),
        venue: eventVenue,
        ticketurl: ticketsLink,
        slug: eventSlug,
      })
      .then((result) => {
        showDialog = true;
        dialogError.message = "Event saved";
        messageType = "";
        window.history.back();
      })
      .catch((error) => {});
  }

  function cancelUpdateEvent() {
    window.history.back();
  }

  function confirmDeleteEvent() {
    showDeleteDialog = true;
  }

  function deleteEvent() {
    showDeleteDialog = false;
    api
      .items("events")
      .deleteOne(eventID)
      .then((result) => {
        showDialog = true;
        dialogError.message = "Event deleted";
        messageType = "";
      })
      .catch((error) => {});
  }
</script>

<div class="container mx-auto h-full items-center p-3">
  {#await eventPromise then eventObject}
    {#if eventObject.user_created == $user.id}
      <!--ul>
        {#each eventObject.performers as performer}
          <li>{performer.performers_id.name}</li>
        {/each}
      </ul-->

      <form on:submit|preventDefault class="container m-auto md:max-w-sm">
        <div class="text-lg ml-6 mb-6">Update event</div>
        <div class="px-6 text-sm">
          <Select
            bind:value={eventVenue}
            outlined
            autocomplete
            label="Venue"
            items={venues}
          />
        </div>

        <div class="px-6 mt-4 mb-6">
          <div class="mb-4">
            <DatePicker
              value={eventDate}
              required
              on:change={(i) => (eventDate = i.detail)}
            />
          </div>
          <div class="flex">
            <Select
              bind:value={eventHour}
              required
              label="Hour"
              dense
              items={hours}
            />
            <Select
              bind:value={eventMinute}
              required
              label="Minute"
              dense
              items={[
                { value: "0", text: "00" },
                { value: "30", text: "30" },
              ]}
            />
            <Select
              bind:value={eventAMPM}
              required
              label="AM/PM"
              dense
              items={[
                { value: "AM", text: "AM" },
                { value: "PM", text: "PM" },
              ]}
            />
          </div>
        </div>

        <div class="px-6">
          <TextField
            label="Tickets Link"
            required="false"
            outlined
            prepend="link"
            bind:value={ticketsLink}
            />
        </div>

        <div class="text-center px-6 mt-16">
          <Button on:click={updateEventFormSubmit} class="rounded-full"
            >Save</Button
          >
          <Button text on:click={cancelUpdateEvent}>Cancel</Button>
        </div>
      </form>

      <div class="fixed bottom-0 left-0 px-6 pb-6">
        <Button text on:click={confirmDeleteEvent} class="text-error-500"
          >Delete Event</Button
        >
      </div>
      <Dialog
        bind:value={showDeleteDialog}
        opacity="0.85"
        class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
      >
        <div class="{messageType} text-center mb-6">Delete this event?</div>
        <div slot="actions">
          <Button class="rounded-full" on:click={deleteEvent}>Delete</Button>
          <Button text on:click={cancelUpdateEvent}>Cancel</Button>
        </div>
      </Dialog>

      <Dialog
        bind:value={showDialog}
        opacity="0.85"
        class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
      >
        <div class="{messageType} text-center">{dialogError.message}</div>
        <div slot="actions">
          <Button on:click={cancelUpdateEvent} text color="white">OK</Button>
        </div>
      </Dialog>
    {:else}
      <div class="mt-24 mb-24 text-error-500 text-center">Access Denied</div>
      <div>
        <Button href="/" block outlined>HOME</Button>
      </div>
    {/if}
  {:catch error}
    <div class="e404">
      {#if error.message == "data.data[0] is undefined"}
        <div class="mb-24 text-error-500">Page not found</div>
        {#if !$user}
          <Button
            href="/user/login?destination={window.location.pathname}"
            block
            outlined>Log in</Button
          >
        {:else}
          <div>
            <Button href="/" block outlined>HOME</Button>
          </div>
        {/if}
      {:else}
        <p class="text-error-500">{error.message}</p>
      {/if}
    </div>
  {/await}
</div>
