<script>
  import { goto, metatags } from "@sveltech/routify";
  import { navTitle } from "../../lib/store.js";
  import { Button, Dialog, List, TextField } from "smelte";
  import { api } from "../../lib/api";
  import { user } from "./auth.js";

  // Redirect to homepage if already logged in
  if ($user) {
    $goto("/");
  }

  // Set page and nav titles
  $: metatags.title = "Complete signup • NightLife.FM";
  navTitle.set("NightLife.FM");

  let completeSignupFormState = {
    password: "",
  };

  let showDialog = false;
  let isError = true;
  let dialogText = "";
  let messageClass = "text-error-500";

  function completeSignupFormSubmit() {
    let urlParams = new URLSearchParams(window.location.search);
    api.transport.axios
      .post("/users/invite/accept", {
        token: urlParams.get("token"),
        password: completeSignupFormState.password,
      })
      .then(function (result) {
        isError = false;
        showDialog = true;
        dialogText = "Signup completed, please log in";
        messageClass = "";
      })
      .catch((error) => {
        showDialog = true;
        dialogText = error.message;
      });
  }
</script>

<form on:submit|preventDefault class="container m-auto mt-12 md:max-w-sm ">
  <div class="mx-3">
    <div class="text-lg mb-6">Complete signup</div>
    <TextField
      label="New password"
      type="password"
      required="true"
      minlegth="8"
      hint="Mininum 8 characters"
      outlined
      prepend="lock"
      bind:value={completeSignupFormState.password}
    />

    <div>
      <Button
        on:click={completeSignupFormSubmit}
        block
        class="mt-6 rounded-full py-3">Complete signup</Button
      >
      <List
        items={[
          { text: "Log in", to: "/user/login" },
          { text: "Forgot password?", to: "/user/request-password" },
        ]}
        dense
        classes="mt-16"
      />
    </div>
  </div>
</form>

<Dialog
  bind:value={showDialog}
  opacity="0.85"
  class="-mt-48 ml-3 mr-3 py-8 w-screen md:w-1/2 lg:w-1/4"
>
  <div class="{messageClass} text-center">{dialogText}</div>
  <div slot="actions" class="px-6 mt-8">
    {#if isError}
      <Button href="/user/request-password" class="rounded-full">
        Request new password
      </Button>
      <Button text color="white" on:click={() => (showDialog = false)}>
        Cancel
      </Button>
    {:else}
      <Button class="rounded-full" href="/user/login">Log in</Button>
    {/if}
  </div>
</Dialog>
