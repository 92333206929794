<script>
  import { metatags } from "@sveltech/routify";
  import { navTitle } from "../lib/store.js";

  $: metatags.title = "Help • NightLife.FM";
</script>

<div class="container mx-3">
  <h4 class="uppercase font-bold">Support</h4>
  <div class="mt-1">For assistance, please contact us at <a href="mailto:info@nightlife.fm"><span class="font-bold text-primary-600">info@nightlife.fm</a></div>
  <div class="mt-4">For privacy policy, please visit our <a href="/privacy"><span class="font-bold text-primary-600">Privacy Policy Page</span></a></div>
</div>
